import React from "react";
// import GettingStartedCode from "../components/GettingStartedCode";

export default function WebScraperGettingStarted() {
  window.location.replace("/fork?repository=https://github.com/genez-io/web-scraper");

  return (
    <React.Fragment>
      {/* <GettingStartedCode
        projectName="web-scraper-getting-started"
        repository="https://github.com/genez-io/web-scraper"
        installDependencies={true}
        title="Web Scraper Getting Started"
        description="This is a simple web scraper that will be deployed as a serverless function on the Genezio Cloud. Please provide a project name to get started."
        stack={["typesafe"]}
      /> */}
    </React.Fragment>
  );
}
