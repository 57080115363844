import React from "react";
// import GettingStartedCode from "../components/GettingStartedCode";

export default function NextJsGettingStarted() {
  window.location.replace("/fork?repository=https://github.com/Genez-io/nextjs-getting-started");

  return (
    <React.Fragment>
      {/* <GettingStartedCode
        projectName="nextjs-getting-started"
        repository="https://github.com/Genez-io/nextjs-getting-started"
        installDependencies={true}
        title="Next JS Getting Started"
        description="This is a simple Next.js application that will be deployed as a serverless function on the Genezio
      Cloud. Please provide a project name to get started."
        stack={["nextjs"]}
      /> */}
    </React.Fragment>
  );
}
