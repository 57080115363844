import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Card, Button, Spinner, Modal, Alert } from "react-bootstrap";
import sdk, { VM } from "@stackblitz/sdk";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { getFrontendByProjectId, getPresignedURLForProjectCode, getProjectById } from "../network/ApiAxios";
import JSZip from "jszip";
import { useParams, useNavigate } from "react-router-dom";
import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { copyToClipboard, nonBinaryFileExtensions } from "../utils";
import { ReactComponent as GenezioAIStarIcon } from "../assets/img/svgs/genezio_ai_star.svg";
import { fileTypeFromBlob, FileTypeResult } from "file-type";
import { getLinkedRepositories, checkIntegrationUser } from "../network/ApiAxios";
import { sendToast } from "../views/Integrations/utils";

const GENEZIOAI_IFRAME = "genezioai-iframe";

const initialFilesToOpen = ["README.md", "index.mjs"];
const ignoreFilesFromInsights = [
  "node_modules",
  "package-lock.json",
  "yarn.lock",
  ".gitignore",
  "LICENSE",
  ".env",
  ".env.prod",
  ".env.dev",
];
const fileNameToNotShowInEditor = ["package-lock.json", "yarn.lock", "pnpm-lock.yaml"];

export interface SourceCodeFile {
  filename: string;
  content: string;
}

export enum ProjectType {
  NEXTJS = "nextjs",
  FUNCTION = "function",
  EXPRESS = "express",
  TYPESAFE = "typesafe",
  VANILLA = "vanilla",
}

const GENEZIO_AI_NOT_SUPPORTED_TECHNOLOGIES = [ProjectType.NEXTJS, ProjectType.TYPESAFE, ProjectType.VANILLA];

export interface SourceCodeFile {
  filename: string;
  content: string;
}

export interface Insights {
  projectType: ProjectType;
  projectLanguage: string;
  codeFromEditor: SourceCodeFile[];
  userEmail?: string;
}

export function EditCode() {
  const { projectId, envId } = useParams<{ projectId?: string; envId: string }>();
  const params = new URLSearchParams(window.location.search);
  const jobID = params.get("jobID");

  const navigate = useNavigate();

  const [project, setProject] = useState<any>({});
  const [activeEnv, setActiveEnv] = useState<any>({});
  const [isIframeVisible, setIsIframeVisible] = useState(false);
  const [vm, setVm] = useState<VM>();

  const [isDeploying, setIsDeploying] = React.useState<boolean>(false);

  const [showModalClone, setShowModalClone] = useState(false);
  const [showModalURLs, setShowModalURLs] = useState(false);
  const [linkedRepos, setLinkedRepos] = useState<any>([]);
  const [isConnectedToGithub, setIsConnectedToGithub] = useState<boolean>(false);

  const checkIfIsConnectedToGithub = async () => {
    const res: any = await checkIntegrationUser("github");
    if (res?.status === 200 && res?.data?.connection_status) {
      setIsConnectedToGithub(true);
    }
  };

  const handleCloseModalClone = () => setShowModalClone(false);
  const handleShowModalClone = () => setShowModalClone(true);

  const handleCloseModalURLs = (openApp: boolean) => {
    setShowModalURLs(false);
    if (openApp == true) {
      // open the app URL in a new tab
      if (frontendsList?.length > 0) {
        window.open(`https://${frontendsList[0].fullDomain}`, "_blank");
      } else if (activeEnv?.functions?.length > 0) {
        window.open(activeEnv.functions[0].cloudUrl, "_blank");
      }
    }
  };
  const handleShowModalURLs = (fromButton: boolean) => {
    if (fromButton) {
      haveShownURLs.current = true;
    }
    setShowModalURLs(true);
    setTimeout(() => {
      haveShownURLs.current = true;
    }, 10000);
  };
  const haveShownURLs = useRef<boolean>(false);

  const [buttonCopyState, setButtonCopyState] = useState<string>("");

  const [showNotFoundModal, setShowNotFoundModal] = useState(false);

  const [frontendsList, setFrontendsList] = useState<any>([]);

  const [cantEditCode, setCantEditCode] = useState<boolean>(false);

  const [vmLoading, setVmLoading] = useState<boolean>(true);

  const [isZipDownloading, setIsZipDownloading] = useState<boolean>(false);

  const [files, setFiles] = React.useState<{ [key: string]: string }>();
  const [filesToOpen, setFilesToOpen] = React.useState<string[]>(initialFilesToOpen);

  const [isGenezioAi, setIsGenezioAi] = useState<boolean>(false);

  const [binaryFiles, setBinaryFiles] = React.useState<{ [key: string]: string }>();
  const [isBase64Encoded, setIsBase64Encoded] = React.useState<{ [key: string]: boolean }>();

  useEffect(() => {
    runAsyncGetProjectInfo(true);
  }, []);

  useEffect(() => {
    checkIfIsConnectedToGithub();
  }, []);

  useEffect(() => {
    fetchLinkedRepos();
  }, []);

  const runAsyncGetProjectInfo = async (firstTime = false) => {
    const res: any = await getProjectById(projectId ?? "");
    let localActiveEnv: any = {};
    let localProject: any = {};
    let localFrontendsList: any = [];
    let stack: string | undefined = undefined;
    if (res.data && res.data.status === "ok") {
      stack = res.data.project.stack;
      const resFrontend: any = await getFrontendByProjectId(projectId ?? "", envId ?? "");
      if (resFrontend.data && resFrontend.data.status === "ok") {
        localFrontendsList = resFrontend.data.list;
        setFrontendsList(resFrontend.data.list);
      }
      localActiveEnv = res.data.project.projectEnvs.find((env: any) => env.id === envId);
      setActiveEnv(localActiveEnv);
      localProject = res.data.project;
      setProject(res.data.project);

      // if showModalURLs is a url parameter
      const params = new URLSearchParams(window.location.search);
      const shouldShowModalURLs = params.get("showModalURLs") === "true";
      if (shouldShowModalURLs) {
        handleShowModalURLs(false);
      }
    } else if (res.response.data.error.code === 2 || res.response.data.error.code === 6) {
      navigate("/dashboard");
      return;
    }

    if (firstTime) {
      createEmbedProject(localProject.name, localProject.region, localActiveEnv.name, stack);
      if (jobID) {
        setIsDeploying(true);
        const token = localStorage.getItem("apiToken") || "";
        let deployInfoId = toast.info("Project Deployment in Progress", {
          position: "top-right",
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        let reason = "";
        const res = await new Promise((resolve, reject) => {
          const interval = setInterval(async () => {
            const jobStatusResponse = await axios.get(process.env.REACT_APP_BUILD_API_BASE_URL + "/state/" + jobID, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });

            const buildStatus = jobStatusResponse.data.BuildStatus;
            if (buildStatus === "FAILED") {
              clearInterval(interval);
              reject();
              return;
            }

            if (buildStatus === "SUCCEEDED") {
              clearInterval(interval);
              resolve(true);
            }

            const transitions: any[] = jobStatusResponse.data.Transitions;
            const reasonNew = transitions.find((t) => t.To === buildStatus)?.Reason;

            if (reason !== reasonNew) {
              reason = reasonNew;
              deployInfoId && toast.dismiss(deployInfoId);
              deployInfoId = toast.info(reason || "Project Deployment in Progress", {
                position: "top-right",
                autoClose: false,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
          }, 1000);
        }).catch((error) => {
          console.error(error);
          deployInfoId && toast.dismiss(deployInfoId);
          toast.error("There was an error deploying the project. Please try again.", {
            position: "top-right",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          return null;
        });

        await runAsyncGetProjectInfo();
        deployInfoId && toast.dismiss(deployInfoId);
        if (res) {
          toast.success("Project deployed successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          if (!haveShownURLs.current) {
            handleShowModalURLs(false);
          }
        }
        setIsDeploying(false);
      }
    }
  };

  React.useEffect(() => {
    document.title = "Genezio | Project Edit Code";
  }, []);

  useEffect(() => {
    if (
      project &&
      project.stack &&
      GENEZIO_AI_NOT_SUPPORTED_TECHNOLOGIES.some((technology) => project.stack.includes(technology))
    ) {
      setIsGenezioAi(false);
    } else {
      setIsGenezioAi(true);
    }
  }, [activeEnv, project]);

  const toggleIframeVisibility = () => {
    setIsIframeVisible(!isIframeVisible);
  };

  const fetchToken = async () => {
    return localStorage.getItem("apiToken");
  };

  const fetchLinkedRepos = async () => {
    const res: any = await getLinkedRepositories(projectId);

    if (res.status === 200 && res.data.status === "ok") {
      setLinkedRepos(res.data.repositories);
      return;
    }

    if (res?.response?.data?.error.code !== 3) {
      sendToast(res?.response?.data?.error?.message || "Unable to fetch linked repositories", "error");
    }
  };

  async function createEmbedProject(projectName: string, region: string, stage: string, stack: string | undefined) {
    const presignedUrl = await getPresignedURLForProjectCode(projectName, region, stage);

    // download the archive from the presigned URL
    const response = await axios
      .get(presignedUrl, {
        responseType: "blob",
      })
      .catch((error) => {
        console.log(error);
        return null;
      });
    if (!response) {
      setShowNotFoundModal(true);
      // delete all #card-code
      const carCode: HTMLElement | null = document.getElementById("card-code");
      if (!carCode) return;
      carCode.style.display = "none";
      return;
    }

    const blob = response.data;
    const zip = new JSZip();
    await zip.loadAsync(blob);
    const files = zip.files;
    const filesContent: any = {};
    const binaryFilesContent: any = {};
    const isBase64EncodedContent: any = {};

    async function readFilesContent(files: any, prefix: string) {
      if (!files) return;

      for (const file of Object.keys(files)) {
        if (
          file.includes("dist/") ||
          file.includes("build/") ||
          file.includes("node_modules/") ||
          fileNameToNotShowInEditor.includes(file)
        ) {
          continue;
        }

        if (files[file].dir) {
          await readFilesContent(files[file].files, `${prefix}${file}/`);
        } else {
          let isBinaryFile: FileTypeResult | boolean | undefined = false;
          // Check if the file extension is in the list of nonBinaryFileExtensions
          const extension = file.split(".").pop();
          if (extension && extension.length > 0 && !nonBinaryFileExtensions.includes(extension)) {
            isBinaryFile = await fileTypeFromBlob(await files[file].async("blob"));
          }
          if (!isBinaryFile) {
            filesContent[`${prefix}${file}`] = await files[file].async("string");
            isBase64EncodedContent[`${prefix}${file}`] = false;
          } else {
            filesContent[`${prefix}${file}`] = "";
            binaryFilesContent[`${prefix}${file}`] = await files[file].async("base64");
            isBase64EncodedContent[`${prefix}${file}`] = true;
          }
        }
      }
    }

    await readFilesContent(files, "");
    setFiles(filesContent);
    setBinaryFiles(binaryFilesContent);
    setIsBase64Encoded(isBase64EncodedContent);

    setVmLoading(true);
    setCantEditCode(false);

    let template: "javascript" | "node" = "javascript";

    // if (stack && stack.indexOf("nextjs") !== -1) {
    //   template = "node";
    // }

    let filesToOpenClone = [...filesToOpen];
    if (stack && stack.includes("typesafe")) {
      filesToOpenClone = ["genezio.yaml", "server/backend.ts", "client/src/App.tsx"];
    } else if (stack && stack.includes("cron")) {
      filesToOpenClone = ["genezio.yaml", "backend.ts"];
    } else if (stack && stack.includes("html")) {
      filesToOpenClone = ["genezio.yaml", "index.html"];
    }

    const vm = await sdk
      .embedProject(
        "embed",
        {
          files: filesContent,
          template: template,
          title: "Getting Started on Genezio",
          description: "A simple project to get you started on Genezio.",
          settings: {
            compile: {
              trigger: "auto",
            },
          },
        },
        {
          openFile: filesToOpenClone.join(","),
          terminalHeight: 0,
          height: "100%",
          showSidebar: true,
          view: "editor",
        },
      )
      .catch((error) => {
        console.log(error);
        setCantEditCode(true);
        return;
      });

    if (vm) {
      setVm(vm);
    }

    setVmLoading(false);
  }

  useEffect(() => {
    async function applyVmDiffs() {
      if (vm) {
        if (files) {
          vm.applyFsDiff({
            create: files,
            destroy: [],
          });
          await vm.editor.openFile(filesToOpen.join(","));
        }
      }
    }

    applyVmDiffs();
  }, [files, filesToOpen, vm]);

  window.addEventListener("message", handleEventMessage);

  async function deployProject(name?: string, region?: string) {
    const iframe: HTMLIFrameElement | null = document.getElementById("embed") as HTMLIFrameElement;
    if (!iframe) return;
    const vm = await sdk.connect(iframe);
    const localFiles = (await vm.getFsSnapshot()) as any;
    const mergedFiles = { ...localFiles };
    if (binaryFiles) {
      for (const fileName in binaryFiles) {
        mergedFiles[fileName] = binaryFiles[fileName];
      }
    }

    const mergedFilesToDeploy: any = {};
    for (const fileName in mergedFiles) {
      const content = mergedFiles[fileName];
      if (isBase64Encoded) {
        mergedFilesToDeploy[fileName] = { content: content, isBase64Encoded: isBase64Encoded[fileName] };
      }
    }

    setIsDeploying(true);
    const token = localStorage.getItem("apiToken");

    const data = JSON.stringify({
      token: token || "",
      type: "s3",
      envId: envId,
      args: {
        projectName: name ? name : project.name,
        region: region ? region : project.region,
        stage: activeEnv.name,
        stack: project.stack || [],
        code: mergedFilesToDeploy,
      },
    });

    let config = {
      method: "post" as const,
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_BUILD_API_BASE_URL + "/deploy",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    await axios
      .request(config)
      .then(async (response) => {
        let deployInfoId = toast.info("Project Deployment in Progress", {
          position: "top-right",
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        let reason = "";
        const jobIDLocal = response.data.jobID;
        const res = await new Promise((resolve, reject) => {
          const interval = setInterval(async () => {
            const jobStatusResponse = await axios.get(
              process.env.REACT_APP_BUILD_API_BASE_URL + "/state/" + jobIDLocal,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              },
            );

            const buildStatus = jobStatusResponse.data.BuildStatus;
            if (buildStatus === "FAILED") {
              clearInterval(interval);
              reject();
              return;
            }

            if (buildStatus === "SUCCEEDED") {
              clearInterval(interval);
              resolve(true);
            }

            const transitions: any[] = jobStatusResponse.data.Transitions;
            const reasonNew = transitions.find((t) => t.To === buildStatus)?.Reason;

            if (reason !== reasonNew) {
              reason = reasonNew;
              deployInfoId && toast.dismiss(deployInfoId);
              deployInfoId = toast.info(reason || "Project Deployment in Progress", {
                position: "top-right",
                autoClose: false,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
          }, 1000);
        }).catch((error) => {
          console.error(error);
          deployInfoId && toast.dismiss(deployInfoId);
          toast.error("There was an error deploying the project. Please try again.", {
            position: "top-right",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          return null;
        });

        await runAsyncGetProjectInfo();
        deployInfoId && toast.dismiss(deployInfoId);
        if (res) {
          toast.success("Project deployed successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          if (!haveShownURLs.current) {
            handleShowModalURLs(false);
          }
        }
        setIsDeploying(false);
        await runAsyncGetProjectInfo();
        toast.success(response.data, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        if (!haveShownURLs.current) {
          handleShowModalURLs(false);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data, {
          position: "top-right",
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });

    setIsDeploying(false);
  }

  async function downloadProject() {
    setIsZipDownloading(true);
    const json = JSON.stringify({
      projectName: project.name,
      region: project.region,
      stage: activeEnv.name,
    });

    const response = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_BASE_URL}/core/get-project-code-url`,
      data: json,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("apiToken") || ""}`,
        "Accept-Version": `genezio-webapp/${process.env.REACT_APP_VERSION}`,
      },
      maxContentLength: Infinity,
      maxBodyLength: Infinity,
    });

    // download response.data.presignedURL
    const link = document.createElement("a");
    link.href = response.data.presignedURL;
    link.setAttribute("download", `${project.name}.zip`);
    document.body.appendChild(link);
    link.click();
    link.remove();
    setIsZipDownloading(false);
  }

  function getCloneSnippet() {
    return `npm i genezio -g
genezio clone --name ${project.name} --region ${project.region} --stage ${activeEnv.name} ./${project.name}`;
  }

  function handleEventMessage(event: MessageEvent) {
    window.addEventListener("message", (event) => {
      if (event.data.type === "FUNCTION_AI_RESPONSE") {
        if (event.data && event.data.code) {
          const code: SourceCodeFile[] = event.data.code;
          const newFiles: { [key: string]: string } = {};
          code.forEach((file) => {
            if (file.content !== "") {
              newFiles[file.filename] = file.content;
            }
          });
          setFiles(newFiles);
          setFilesToOpen(Object.keys(newFiles));
        }
      } else if (event.data.type === "REQUEST_TOKEN") {
        fetchToken().then((token) => {
          const iframe = document.getElementById(GENEZIOAI_IFRAME) as HTMLIFrameElement;
          if (iframe && iframe.contentWindow) {
            iframe.contentWindow.postMessage({ type: "AUTH_TOKEN", token }, event.origin);
          }
        });
      } else if (event.data.type === "REQUEST_INSIGHTS") {
        const iframe = document.getElementById(GENEZIOAI_IFRAME) as HTMLIFrameElement;
        let codeFromEditor: SourceCodeFile[] = [];
        if (vm) {
          vm.getFsSnapshot().then((editorFiles) => {
            if (editorFiles) {
              codeFromEditor = Object.keys(editorFiles).map((filename) => {
                if (ignoreFilesFromInsights.includes(filename)) {
                  return {
                    filename: filename,
                    content: "",
                  };
                }
                return {
                  filename: filename,
                  content: editorFiles[filename],
                };
              });

              const user = localStorage.getItem("user");
              let userEmail = "";
              if (user) {
                userEmail = JSON.parse(user).email;
              }
              const insights: Insights = {
                projectType: ProjectType.FUNCTION,
                projectLanguage: "javascript",
                codeFromEditor: codeFromEditor,
                userEmail: userEmail,
              };
              if (iframe && iframe.contentWindow) {
                iframe.contentWindow.postMessage({ type: "INSIGHTS", insights }, event.origin);
              }
            }
          });
        }
      }
    });
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <Modal show={showNotFoundModal} className="smaller-modal-width" centered>
        <Modal.Header closeButton>
          <Modal.Title>Source Code Not Found</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Source code for this project is not on the server.
            <br />
            Please deploy the project to get the source code here.
          </p>
          <p>Make sure you have Genezio updated by running:</p>
          <SyntaxHighlighter
            customStyle={{
              fontSize: "15px",
              fontWeight: "500",
            }}
            className="p-4 rounded"
            language={"npm"}
            style={atomOneDark}
          >
            npm update genezio -g
          </SyntaxHighlighter>
          {/* <p>Please deploy the project to get the source code here.</p> */}
          <p className="mt-3">
            Run the following command in your terminal in the root folder of the project to deploy it:
          </p>
          <SyntaxHighlighter
            customStyle={{
              fontSize: "15px",
              fontWeight: "500",
            }}
            className="p-4 rounded"
            language={"Text"}
            style={atomOneDark}
          >
            genezio deploy
          </SyntaxHighlighter>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => navigate(`/project/${projectId}/${envId}/`)}>Back To Project</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showModalURLs} onHide={() => handleCloseModalURLs(false)} className="medium-modal-width" centered>
        <Modal.Header closeButton>
          <Modal.Title>{haveShownURLs.current ? <>Your App URLs</> : <>Project succesfully deployed</>}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {activeEnv?.classes?.length > 0 && (
            <div style={{ marginBottom: "1em" }}>
              <h6>Type-safe classes</h6>
              <div className="mt-2">
                Test your classes using the{" "}
                <a
                  className="p-0 mb-1"
                  href={`/test-interface/${projectId}/${envId}`}
                  target="_blank"
                  style={{ textDecoration: "underline" }} rel="noreferrer"
                >
                  genezio test interface
                </a>{" "}
              </div>
            </div>
          )}

          {!project?.stack?.includes("nextjs") && activeEnv?.functions?.length > 0 && (
            <div style={{ marginBottom: "1em" }}>
              <h6>Deployed functions</h6>
              <div className="mt-2">
                <ul className="m-0 p-0" style={{ listStyle: "none" }}>
                  {activeEnv.functions.map((func: { name: string; cloudUrl: string }) => (
                    <li className="mb-2" key={func.name}>
                      <b>{func.name}: </b>
                      <a style={{ textDecoration: "underline" }} href={func.cloudUrl} target="_blank" rel="noreferrer">
                        {func.cloudUrl}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}

          {frontendsList?.length > 0 && (
            <div style={{ marginBottom: "1em" }}>
              <h6>Deployed frontends</h6>
              <div className="mt-2">
                <ul style={{ listStyle: "none" }} className="m-0 p-0">
                  {frontendsList.map((frontend: any) => (
                    <li className="mb-0" key={frontend.id}>
                      <a
                        className="text-dark"
                        style={{ textDecoration: "underline" }}
                        href={`https://${frontend.fullDomain}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://{frontend.fullDomain}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="text-start flex-end justify-content-end">
          <Button
            variant="secondary"
            className="border rounded py-2 px-4 mx-2 env-vars-btn"
            onClick={() => handleCloseModalURLs(false)}
          >
            Close
          </Button>
          {(frontendsList.length > 0 || activeEnv?.functions?.length > 0) && (
            <Button className="border rounded py-2 px-4 mx-2 env-vars-btn" onClick={() => handleCloseModalURLs(true)}>
              Open App
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      <Modal show={showModalClone} onHide={handleCloseModalClone} className="medium-modal-width" centered>
        <Modal.Header closeButton>
          <Modal.Title>Clone your Project</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!isConnectedToGithub || !linkedRepos?.length ? (
            <>
              <p>You can clone the project locally by running the following commands in your terminal:</p>
              <SyntaxHighlighter
                customStyle={{
                  fontSize: "15px",
                  fontWeight: "500",
                }}
                className="p-4 rounded"
                language={"Text"}
                style={atomOneDark}
              >
                {getCloneSnippet()}
              </SyntaxHighlighter>

              <div
                onClick={() => copyToClipboard(getCloneSnippet(), "codeSnippet", buttonCopyState, setButtonCopyState)}
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  right: "30px",
                  top: "10px",
                  width: "100%",
                  maxWidth: "20px",
                }}
              >
                {buttonCopyState === "codeSnippet" ? (
                  <i style={{ fontSize: "20px" }} className="fa fa-check text-success" aria-hidden="true" />
                ) : (
                  <img
                    src={require("./../assets/img/svgs/copy_icon.svg").default}
                    alt="Copy"
                    style={{ width: "120%", maxWidth: "120%" }}
                  />
                )}
              </div>
            </>
          ) : (
            ""
          )}

          {linkedRepos?.length && isConnectedToGithub ? (
            <>
              <p className="my-3">You can clone the project using git clone command:</p>
              <SyntaxHighlighter
                customStyle={{
                  fontSize: "15px",
                  fontWeight: "500",
                  color: "red",
                }}
                className="p-4 rounded"
                language={"markdown"}
                style={atomOneDark}
              >
                {`git clone https://github.com/${linkedRepos[0]?.owner}/${linkedRepos[0]?.name}`}
              </SyntaxHighlighter>
              <div
                onClick={() =>
                  copyToClipboard(
                    `git clone https://github.com/${linkedRepos[0]?.owner}/${linkedRepos[0]?.name}`,
                    "cloneRepoSnippet",
                    buttonCopyState,
                    setButtonCopyState,
                  )
                }
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  right: "30px",
                  bottom: "100px",
                  width: "100%",
                  maxWidth: "20px",
                }}
              >
                {buttonCopyState === "cloneRepoSnippet" ? (
                  <i style={{ fontSize: "20px" }} className="fa fa-check text-success" aria-hidden="true" />
                ) : (
                  <img
                    src={require("./../assets/img/svgs/copy_icon.svg").default}
                    alt="Copy"
                    style={{ width: "120%", maxWidth: "120%" }}
                  />
                )}
              </div>
            </>
          ) : (
            ""
          )}
        </Modal.Body>
        <Modal.Footer className="text-start flex-end justify-content-end">
          <Button className="border rounded py-2 px-4 mx-2 env-vars-btn" onClick={() => handleCloseModalClone()}>
            Close
          </Button>
          <Button onClick={() => downloadProject()} className="btn btn-primary ms-2">
            {isZipDownloading ? "Downloading Zip..." : "Download Zip Archive"}{" "}
            {isZipDownloading && <Spinner variant="" animation="border" size="sm" className="mx-2" />}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* <!-- row  --> */}
      <Row>
        <Col sm={12} className="col-12">
          <Card id="card-code">
            <div style={{ display: "flex", margin: "0px", height: "calc(100vh - 69px)" }}>
              {isIframeVisible && (
                <div style={{ width: "50%", height: "100%", position: "relative" }}>
                  <>
                    <iframe
                      id={GENEZIOAI_IFRAME}
                      src={process.env.REACT_APP_GENEZIO_AI_URL}
                      style={{ width: "100%", height: "100%", border: "none" }}
                      title="Genezio AI Micro Frontend"
                    />
                    <Button
                      onClick={toggleIframeVisibility}
                      style={{
                        position: "absolute",
                        top: "10px",
                        left: "10px",
                        fontSize: "10px",
                        padding: "4px",
                        width: "16px",
                        height: "16px",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        lineHeight: "1",
                        border: "1px solid gray",
                        backgroundColor: "transparent",
                        color: "gray",
                        transition: "background-color 0.3s, color 0.3s",
                      }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.backgroundColor = "gray";
                        e.currentTarget.style.color = "white";
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.backgroundColor = "transparent";
                        e.currentTarget.style.color = "gray";
                      }}
                    >
                      &#x2715;
                    </Button>
                  </>
                </div>
              )}
              <Card.Body className="p-0 example1-table" style={{ height: "100%" }}>
                <div
                  className={`editor-header ${cantEditCode ? "cant-edit" : ""}`}
                  style={{
                    display: vmLoading ? "none" : "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    minHeight: "42px",
                    height: "auto",
                  }}
                >
                  <div>
                    <Button
                      onClick={() => deployProject()}
                      disabled={isDeploying || cantEditCode || vmLoading}
                      className="first"
                    >
                      {!isDeploying ? "Deploy" : "Deploying"}
                      {isDeploying && <Spinner variant="" animation="border" size="sm" className="mx-2" />}
                    </Button>
                    <Button onClick={() => handleShowModalClone()}>Clone Locally</Button>
                    {isGenezioAi && (
                      <Button
                        onClick={() => toggleIframeVisibility()}
                        disabled={vmLoading || cantEditCode}
                        className="genezioai-button"
                      >
                        <GenezioAIStarIcon />
                        Ask Genezio AI (Beta)
                      </Button>
                    )}
                  </div>

                  <div>
                    <Button
                      disabled={isDeploying || vmLoading}
                      onClick={() => handleShowModalURLs(true)}
                      className="mx-0 mx-md-2"
                    >
                      View App URLs
                    </Button>
                  </div>
                </div>
                <div
                  style={{
                    opacity: cantEditCode || vmLoading ? "0" : "1",
                    display: cantEditCode ? "none" : "block",
                    height: vmLoading ? "0" : "calc(100% - 42px)",
                  }}
                >
                  <div id="embed" style={{ height: "100%" }}></div>
                </div>
                {vmLoading && (
                  <div
                    className="w-100 d-flex justify-content-center align-items-center flex-column"
                    style={{ height: "90vh" }}
                  >
                    <p className="m-0">
                      Your app is deployed and available{" "}
                      <span
                        className="text-primary text-decoration-underline"
                        onClick={() => setShowModalURLs(true)}
                        style={{ cursor: "pointer" }}
                      >
                        here
                      </span>
                      .
                    </p>
                    <p>In just a few seconds, you'll be able to edit your code in the browser.</p>
                    <Spinner variant="primary" animation="border" />
                  </div>
                )}

                {cantEditCode && (
                  <Alert variant="info">
                    <p className="alert-heading h5">The project is too large to edit in the browser.</p>
                    <p className="h6">You can clone the project locally and edit it in your favorite code editor.</p>
                  </Alert>
                )}
              </Card.Body>
            </div>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}
