export const PROJECT_TABLE_COLUMNS: any = [
  {
    Header: "ID",
    accessor: "PurchaseDate",
  },
  {
    Header: "Project Name",
    accessor: "name",
  },
  {
    Header: "Last Edit Date",
    accessor: "ProductID",
  },
  {
    Header: "Created Date",
    accessor: "Product",
  },
  {
    Header: " ",
    accessor: " ",
  },
];
