import React, { useState } from "react";
import { Col, Form, Row, Alert } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { GoogleOAuthProvider, GoogleLogin, CredentialResponse } from "@react-oauth/google";
import axios from "axios";
import { getUserInformation } from "../../network/ApiAxios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const SignUp = () => {
  const [err, setError] = useState("");

  let navigate = useNavigate();

  React.useEffect(() => {
    document.title = "Genezio | Sign Up";
  }, []);

  const urlParams = new URLSearchParams(window.location.search);

  const collaborationProjectInvite: string | undefined = urlParams.get("invitationId")?.toString();

  const handleLoginGoogle = (googleData: CredentialResponse) => {
    const options = {};

    axios
      .post(API_BASE_URL + "/users/google-auth", { googleToken: googleData.credential }, options)
      .then(async (res) => {
        const { status, token, user, error } = res.data;
        if (status === "ok") {
          localStorage.setItem("apiToken", token);
          localStorage.setItem("user", JSON.stringify(user));
          const userId: string = (await getUserInformation()).data?.user?.id || "";
          // @ts-ignore
          window.dataLayer = window.dataLayer || [];
          // @ts-ignore
          window.dataLayer.push({
            userId: userId,
            event: "userIdSet",
          });
          // check if redirect == cli from query params
          const urlParams = new URLSearchParams(window.location.search);
          const redirect: string = urlParams.get("redirect")?.toString() || "";
          if (redirect === "cli") {
            navigate("/clilogin", { replace: true });
          } else if (redirect === "express-getting-started") {
            navigate("/express-getting-started", { replace: true });
          } else if (redirect === "new-project") {
            navigate("/new-project", { replace: true });
          } else if (redirect === "html-getting-started") {
            navigate("/html-getting-started", { replace: true });
          } else if (redirect === "angular-getting-started") {
            navigate("/angular-getting-started", { replace: true });
          } else if (redirect === "react-getting-started") {
            navigate("/react-getting-started", { replace: true });
          } else if (redirect === "faas-getting-started") {
            navigate("/faas-getting-started", { replace: true });
          } else if (redirect === "vue-getting-started") {
            navigate("/vue-getting-started", { replace: true });
          } else if (redirect === "fastify-getting-started") {
            navigate("/fastify-getting-started", { replace: true });
          } else if (redirect === "express-fullstack-getting-started") {
            navigate("/express-fullstack-getting-started", { replace: true });
          } else if (collaborationProjectInvite) {
            navigate(`/collaboration/invitation/${collaborationProjectInvite}`, { replace: true });
          } else {
            navigate("/dashboard", { replace: true });
          }
        } else {
          setError(error.message);
        }
      })
      .catch(function (error) {
        console.error(error);
        setError("There was an error logging in. Please try again.");
      });
  };

  return (
    <React.Fragment>
      <div className="page bg-white">
        <div className="page-single">
          <div className="container" style={{ marginTop: "20vh" }}>
            <Row>
              <Col
                xl={5}
                lg={6}
                md={8}
                sm={8}
                xs={10}
                className="card-sigin-main mx-auto my-auto py-4 justify-content-center"
              >
                <div className="card-sigin" style={{ border: "3px solid #6F42C1" }}>
                  {/* <!-- Demo content--> */}
                  <div className="main-card-signin d-md-flex">
                    <div className="wd-100p">
                      <div className="d-flex mb-4 justify-content-center">
                        <img
                          src={require("../../assets/img/brand/icon-black.svg").default}
                          className="sign-favicon ht-40"
                          alt="logo"
                        />
                      </div>
                      <div className="">
                        <div className="main-signup-header">
                          <h2 className="text-center mb-4 pb-3" style={{ fontSize: 40 }}>
                            Sign Up
                          </h2>
                          <div className="panel panel-primary">
                            <div className=" tab-menu-heading mb-2 border-bottom-0">
                              <div className="tabs-menu1">
                                {err && <Alert variant="danger">{err}</Alert>}
                                <Form>
                                  <div
                                    className="row"
                                    style={{
                                      justifyContent: "center",
                                    }}
                                  >
                                    <div
                                      className="d-flex justify-content-center col-lg-6"
                                      id="google_login_wrapper_btn"
                                    >
                                      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID!}>
                                        <GoogleLogin
                                          shape="pill"
                                          size="medium"
                                          text="signup_with"
                                          // width expressed in px
                                          // do not change, otherwise it won't match GitHub button width
                                          width={217}
                                          onSuccess={(credentialResponse: CredentialResponse) => {
                                            handleLoginGoogle(credentialResponse);
                                          }}
                                          onError={() => {
                                            console.log("Login Failed");
                                          }}
                                        />
                                      </GoogleOAuthProvider>
                                    </div>
                                    <div className="d-flex justify-content-center col-lg-12 my-2"></div>
                                    {/* login github button */}
                                    <div className="d-flex justify-content-center col-lg-7 p-0">
                                      <a
                                        href={`https://github.com/login/oauth/authorize?client_id=${
                                          process.env.REACT_APP_GITHUB_CLIENT_ID
                                        }&scope=user&redirect_uri=${encodeURIComponent(
                                          `${
                                            process.env.REACT_APP_BASE_URL
                                          }/auth/github-redirect?invitationId=${collaborationProjectInvite}${
                                            urlParams.get("redirect")?.toString()
                                              ? `&redirect=${urlParams.get("redirect")?.toString()}`
                                              : ""
                                          }`,
                                        )}`}
                                        className="btn login-btn-github"
                                        type="button"
                                      >
                                        <i className="fab fa-github"></i>

                                        <span>Sign up with GitHub</span>
                                      </a>
                                    </div>
                                  </div>
                                  <div
                                    className="mt-4 text-center justify-content-center mb-2"
                                    style={{
                                      color: "#6F42C1",
                                      fontSize: 12,
                                    }}
                                  >
                                    By signing up you agree with our{" "}
                                    <a
                                      style={{
                                        color: "#036DB5",
                                      }}
                                      href="https://genezio.com/eula/"
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {" "}
                                      Terms & Conditions.
                                    </a>{" "}
                                    <br></br>
                                    Already have an account?{" "}
                                    <Link
                                      style={{
                                        color: "#036DB5",
                                      }}
                                      to={
                                        !collaborationProjectInvite
                                          ? "/auth/login"
                                          : `/auth/login?invitationId=${collaborationProjectInvite}`
                                      }
                                    >
                                      Sign In
                                    </Link>
                                  </div>
                                  <div className="mt-4 d-flex text-center justify-content-center mb-2">
                                    <a
                                      href="https://x.com/geneziodev"
                                      target="_blank"
                                      className="btn btn-icon me-3 border rounded-circle"
                                      type="button"
                                      rel="noreferrer"
                                    >
                                      <svg
                                        width="13"
                                        height="13"
                                        viewBox="0 0 1200 1227"
                                        fill="#6f42c1"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z"
                                          fill="#6f42c1"
                                        />
                                      </svg>
                                    </a>

                                    <a
                                      href="https://www.linkedin.com/company/genez-io"
                                      target="_blank"
                                      className="btn btn-icon me-3"
                                      type="button"
                                      rel="noreferrer"
                                    >
                                      <span className="btn-inner--icon">
                                        {" "}
                                        <i className="bx bxl-linkedin tx-18 tx-prime"></i>{" "}
                                      </span>
                                    </a>
                                  </div>
                                  {/* <div className="main-signin-footer text-center mt-3">
                                    <p>
                                      <Link to="#" className="mb-3">
                                        Forgot password?
                                      </Link>
                                    </p>
                                    <p>
                                      Don't have an account ?{" "}
                                      <Link
                                        to={`${process.env.PUBLIC_URL}/authentication/signup`}
                                        className=""
                                      >
                                        {" "}
                                        Create an Account
                                      </Link>
                                    </p>
                                  </div> */}
                                </Form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

SignUp.propTypes = {};

SignUp.defaultProps = {};

export default SignUp;
