import React, { useEffect, useState, useRef } from "react";
import { Col, Row, Card, Form, Button, Spinner } from "react-bootstrap";
import GenezioSpinner from "../../components/Utilities/Spinner/GenezioSpinner";
import { getFrontendByProjectId, getUserInformation, deleteSubdomain } from "../../network/ApiAxios";
import FrontendElement from "./FrontendElement";
import { useParams } from "react-router-dom";
import DeleteSubdomainModal from "../../components/AdvancedUI/Modals/DeleteSubdomainModal";

export default function Frontend(props: any) {
  const { projectId, envId } = useParams<{ projectId?: string; envId: string }>();
  const [frontendsList, setFrontendsList] = useState<any>([]);
  const [selectedFrontend, setSelectedFrontend] = useState<any>(null);
  const [deleteSubdomainLoading, setDeleteSubdomainLoading] = useState<boolean>(false);
  const [deleteSubdomainModal, setDeleteSubdomainModal] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const updateDomain = useRef<boolean>(false);

  const [userInfo, setUserInfo] = useState<any>({}); // user info

  const [refreshUpdatedDomain, setRefreshUpdatedDomain] = useState<number>(0);

  useEffect(() => {
    async function asyncFetch() {
      setIsLoading(true);
      const resp: any = await getUserInformation();
      setIsLoading(false);

      if (resp.response && resp.response.data.error) {
        alert(resp.response.data.error.message);
        return;
      }

      if (resp.data && resp.data.error) {
        alert(resp.data.error.message);
        return;
      }

      if (resp.data && resp.data.user) {
        setUserInfo(resp.data.user);
        return;
      }
    }
    asyncFetch();
  }, []);

  useEffect(() => {
    const runAsync = async () => {
      setIsLoading(true);
      fetchUserFrontends();
      setIsLoading(false);
    };
    runAsync();
  }, [updateDomain.current, refreshUpdatedDomain]);

  function refreshFrontends() {
    updateDomain.current = !updateDomain.current;
    setRefreshUpdatedDomain(refreshUpdatedDomain + 1);
  }

  const fetchUserFrontends = async () => {
    const res: any = await getFrontendByProjectId(projectId ?? "", envId ?? "");
    if (res.data && res.data.status === "ok") {
      setFrontendsList(res.data.list);
      if (res.data.list.length > 0) {
        if (selectedFrontend === null) {
          setSelectedFrontend(res.data.list[0]);
        } else {
          const newSelectedFrontend = res.data.list.find(
            (frontend: any) =>
              (!(frontend.domainStatus === "CUSTOM_DOMAIN_VALIDATED") ? frontend.fullDomain : frontend.customDomain) ===
              (!(selectedFrontend.domainStatus === "CUSTOM_DOMAIN_VALIDATED")
                ? selectedFrontend.fullDomain
                : selectedFrontend.customDomain),
          );
          if (newSelectedFrontend) {
            setSelectedFrontend(newSelectedFrontend);
          } else {
            setSelectedFrontend(res.data.list[0]);
          }
        }
      } else {
        setSelectedFrontend(null);
      }
    } else {
      setFrontendsList([]);
      setSelectedFrontend(null);
      alert(res.data.error.message);
    }
  };

  if (props.overview && !selectedFrontend) return null;

  if (isLoading) {
    return (
      <Card.Body className="pt-0">
        <Row className="pb-2">
          <Col sm={12} className="col-12">
            <GenezioSpinner />
          </Col>
        </Row>
      </Card.Body>
    );
  }

  if (selectedFrontend === null) {
    return (
      <Card.Body className="pt-0">
        <div>
          <Row className="pb-2">
            <Col sm={12} className="col-12 text-center p-5">
              <h4>Your frontend is not deployed!</h4>
              <p className="mb-3 ">Information about your frontend will be shown here after the first deployment.</p>
              <a
                href={process.env.REACT_APP_GENEZIO_DOCUMENTATION + "features/frontend-deployment"}
                target="_blank"
                rel="noreferrer"
                className="frontend-deply-btn-how-to-deploy p-1 px-2"
              >
                How to deploy a frontend
              </a>
            </Col>
          </Row>
        </div>
      </Card.Body>
    );
  }

  return (
    <React.Fragment>
      {frontendsList.length > 0 && props.overview && (
        <Card.Header>
          <h4 className="card-title">Domains</h4>
        </Card.Header>
      )}

      <DeleteSubdomainModal
        selectedSubdomain={selectedFrontend?.genezioDomain}
        show={deleteSubdomainModal}
        isDeletingLoading={deleteSubdomainLoading}
        onHide={() => setDeleteSubdomainModal(false)}
        onDelete={async () => {
          setDeleteSubdomainLoading(true);
          await deleteSubdomain(selectedFrontend?.genezioDomain);
          fetchUserFrontends();
          setDeleteSubdomainModal(false);
          setDeleteSubdomainLoading(false);
        }}
      />

      {frontendsList.length > 1 && !props.overview ? (
        <Card.Body className="pt-0">
          <div>
            <Col md={8} className="p-0">
              <h5 className="mb-3">Choose a frontend:</h5>
              <Row className="m-0 align-items-center">
                <Form.Select
                  aria-label="Default select example"
                  value={
                    selectedFrontend
                      ? !(selectedFrontend.domainStatus === "CUSTOM_DOMAIN_VALIDATED")
                        ? selectedFrontend.fullDomain
                        : selectedFrontend.customDomain
                      : ""
                  }
                  defaultValue={
                    selectedFrontend
                      ? !(selectedFrontend.domainStatus === "CUSTOM_DOMAIN_VALIDATED")
                        ? selectedFrontend.fullDomain
                        : selectedFrontend.customDomain
                      : ""
                  }
                  onChange={(e: any) => {
                    setSelectedFrontend(
                      frontendsList.find((frontend: any) => {
                        const frontendDomain = !(frontend.domainStatus === "CUSTOM_DOMAIN_VALIDATED")
                          ? frontend.fullDomain
                          : frontend.customDomain;

                        return frontendDomain === e.target.value;
                      }),
                    );
                  }}
                >
                  <option disabled>Choose a frontend</option>
                  {frontendsList.map((frontend: any) => {
                    const frontendDomain = !(frontend.domainStatus === "CUSTOM_DOMAIN_VALIDATED")
                      ? frontend.fullDomain
                      : frontend.customDomain;

                    const selectedFrontendDomainLocal = !(selectedFrontend.domainStatus === "CUSTOM_DOMAIN_VALIDATED")
                      ? selectedFrontend.fullDomain
                      : selectedFrontend.customDomain;

                    return (
                      <option
                        key={frontendDomain}
                        value={frontendDomain}
                        selected={selectedFrontendDomainLocal === frontendDomain}
                      >
                        {frontendDomain}
                      </option>
                    );
                  })}
                </Form.Select>
                <Button
                  variant="outline-danger"
                  className="p-0 m-2 p-2"
                  disabled={deleteSubdomainLoading}
                  onClick={async () => {
                    setDeleteSubdomainModal(true);
                  }}
                >
                  Remove Subdomain
                  {deleteSubdomainLoading && <Spinner size="sm" variant="danger" className="mx-2" animation="border" />}
                </Button>
              </Row>
            </Col>
          </div>
        </Card.Body>
      ) : (
        ""
      )}

      {selectedFrontend && (
        <FrontendElement
          userInfo={userInfo}
          refreshFrontends={refreshFrontends}
          currentCollaboratorDetails={props.currentCollaboratorDetails}
          frontend={selectedFrontend}
          overview={props.overview}
        />
      )}
    </React.Fragment>
  );
}
