import { useState } from "react";
import { Button, Modal, Form, FormGroup, Spinner } from "react-bootstrap";
import Select from "react-select";
import { createUpstashDatabase } from "../../../network/ApiAxios";
import { sendToast } from "../../../views/Integrations/utils";
import { UpstashDatabaseProps } from "../../../models/IntegrationModels";

const region = [
  { label: "eu-west-1", value: "eu-west-1" },
  { label: "us-east-1", value: "us-east-1" },
  { label: "us-west-1", value: "us-west-1" },
  { label: "ap-northeast-1", value: "ap-northeast-1" },
  { label: "us-central1", value: "us-central1" },
];

const primaryRegion = [
  { label: "us-east-1", value: "us-east-1" },
  { label: "us-west-1", value: "us-west-1" },
  { label: "us-west-2", value: "us-west-2" },
  { label: "eu-west-1", value: "eu-west-1" },
  { label: "eu-central-1", value: "eu-central-1" },
  { label: "ap-southeast-1", value: "ap-southeast-1" },
  { label: "ap-southeast-2", value: "ap-southeast-2" },
  { label: "sa-east-1", value: "sa-east-1" },
];

const readRegion = [
  { label: "us-east-1", value: "us-east-1" },
  { label: "us-west-1", value: "us-west-1" },
  { label: "us-west-2", value: "us-west-2" },
  { label: "eu-west-1", value: "eu-west-1" },
  { label: "eu-central-1", value: "eu-central-1" },
  { label: "ap-southeast-1", value: "ap-southeast-1" },
  { label: "ap-southeast-2", value: "ap-southeast-2" },
  { label: "sa-east-1", value: "sa-east-1" },
];

type DatabaseType = "global" | "regional";

const REGIONAL_DATABASE_TYPE: DatabaseType = "regional";
const GLOBAL_DATABASE_TYPE: DatabaseType = "global";

const DEFAULT_DATABASE_TYPE: DatabaseType = REGIONAL_DATABASE_TYPE;

const UpstashDatabase: React.FC<UpstashDatabaseProps> = ({
  handleDatabaseSelectDefault,
  onAddDBModalClose,
  open,
}) => {
  const [formData, setFormData] = useState<any>({
    region: DEFAULT_DATABASE_TYPE,
  });
  const [databaseType, setDatabaseType] = useState<DatabaseType>(DEFAULT_DATABASE_TYPE);
  const [loading, setLoading] = useState<boolean>(false);

  // create database
  const handleCreate = async () => {
    setLoading(true);
    const res: any = await createUpstashDatabase(formData);

    if (res?.response?.data?.error.code === 6 || res?.response?.data?.error.code === 2) {
      sendToast(res.response.data.error.message, "error");
    }

    if (res.data) {
      handleDatabaseSelectDefault(res?.data?.database);
    }
    handleCancel();
    setLoading(false);
  };

  //  hide database modal
  const handleCancel = () => {
    onAddDBModalClose();
    setFormData({});
    setDatabaseType(DEFAULT_DATABASE_TYPE);
  };

  const handleInputChange = (name: string, e: any) => {
    //  Based on this variable we handle values if we have a multi-value selector
    const isMultiSelector = Array.isArray(e) ? true : false;
    let selectedValues: string[];
    if (isMultiSelector) {
      selectedValues = e.map((option: any) => option.value);
    }
    // Set data to formData state
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: isMultiSelector ? selectedValues : e,
    }));
  };

  return (
    <Modal show={open} onHide={() => handleCancel()} className="smaller-modal-width" centered>
      <Modal.Body style={{ padding: "30px" }}>
        <h6 className="mb-2" style={{ fontWeight: "600" }}>
          Create Database
        </h6>
        <Form>
          <p style={{ fontWeight: "500" }} className="mb-1">
            Name
          </p>
          <FormGroup className="form-group">
            <Form.Control
              type="text"
              className="form-control"
              size="sm"
              id="databaseName"
              placeholder="Database Name"
              onChange={(e) => handleInputChange("name", e.target.value)}
            />
          </FormGroup>

          <p style={{ fontWeight: "500" }} className="mb-1">
            Type
          </p>
          <FormGroup className="form-group">
          <button
              onClick={(e) => {
                setDatabaseType(REGIONAL_DATABASE_TYPE);
                const updatedFormData = { ...formData };
                delete updatedFormData.read_region;
                delete updatedFormData.primary_region;
                delete updatedFormData.region;
                setFormData(updatedFormData);
                e.preventDefault();
              }}
              style={{ borderTopRightRadius: "0", borderBottomRightRadius: "0" }}
              className={databaseType === REGIONAL_DATABASE_TYPE ? "btn btn-primary" : "border btn"}
            >
              Regional
            </button>
            <button
              onClick={(e) => {
                setDatabaseType(GLOBAL_DATABASE_TYPE);
                const updatedFormData = {
                  ...formData,
                  region: GLOBAL_DATABASE_TYPE,
                };
                setFormData(updatedFormData);
                e.preventDefault();
              }}
              style={{ borderTopLeftRadius: "0", borderBottomLeftRadius: "0" }}
              className={databaseType === GLOBAL_DATABASE_TYPE ? "btn btn-primary" : "border btn"}
            >
              Global
            </button>
          </FormGroup>

          {databaseType === GLOBAL_DATABASE_TYPE ? (
            <>
              <p style={{ fontWeight: "500" }} className="mb-1">
                Primary Region
              </p>
              <FormGroup className="form-group">
                <Select
                  // add this filter because we should not be able to add read region same as primary region
                  options={primaryRegion.filter((region: any) => !formData["read_region"]?.includes(region.label))}
                  onChange={(e: any) => handleInputChange("primary_region", e.value)}
                  placeholder="Primary region"
                  classNamePrefix="selectform"
                />
                <p className="text-muted mt-1">Choose the region where most of your writes will take place.</p>
              </FormGroup>

              <p style={{ fontWeight: "500" }} className="mb-1">
                Read Regions
              </p>
              <FormGroup className="form-group">
                <Select
                  // add this filter because we should not be able to add read region same as primary region
                  options={readRegion.filter((region: any) => region.label !== formData["primary_region"])}
                  onChange={(e: any) => handleInputChange("read_region", e)}
                  isMulti={true}
                  placeholder="Read region"
                  classNamePrefix="selectform"
                />
                <p className="text-muted mt-1">Choose the regions where most of your reads will take place.</p>
              </FormGroup>
            </>
          ) : (
            <>
              <p style={{ fontWeight: "500" }} className="mb-1">
                Region
              </p>
              <FormGroup className="form-group">
                <Select
                  key={formData}
                  options={region}
                  onChange={(e: any) => handleInputChange("region", e.value)}
                  placeholder="Region"
                  classNamePrefix="selectform"
                />
                <p className="text-muted mt-1">
                  For best performance, select the region that is closer to your application.
                </p>
              </FormGroup>
            </>
          )}

          <FormGroup className="form-group">
            <Form.Check
              label={"TLS (SSL) Enabled"}
              key={"option"}
              name="tls"
              type={"checkbox"}
              id={"tls-checkbox"}
              value={"option"}
              onClick={(e: any) => handleInputChange("tls", e.target.checked)}
              className="welcomeFormInput d-flex align-items-center"
            />
            <p className="text-muted px-1 mx-3">This option encrypts the data traffic.</p>
          </FormGroup>

          <FormGroup className="form-group">
            <Form.Check
              label={"Eviction"}
              key={"option"}
              name="eviction"
              type={"checkbox"}
              id={"eviction-checkbox"}
              value={"option"}
              onClick={(e: any) => handleInputChange("eviction", e.target.checked)}
              className="welcomeFormInput d-flex align-items-center"
            />
            <p className="text-muted px-1 mx-3">Enable to evict entries when max data size is reached.</p>
          </FormGroup>
        </Form>

        <div className="mt-4 d-flex align-items-center justify-content-end">
          <Button onClick={() => handleCancel()} className="border rounded py-1 px-4 mx-2 env-vars-btn">
            Cancel
          </Button>
          <Button
            disabled={
              loading
                ? true
                : !formData["name"] ||
                  (databaseType === GLOBAL_DATABASE_TYPE ? !formData["primary_region"] : !formData["region"])
            }
            onClick={() => handleCreate()}
            className="btn py-1 px-4 d-flex align-items-center justify-content-center"
          >
            Create
            {loading && <Spinner className="ms-2" animation="border" size="sm" />}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UpstashDatabase;
