import React, { useEffect, useState, useRef } from "react";
import { Col, Row, Card, Button, InputGroup, Spinner, Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import GenezioSpinner from "../../components/Utilities/Spinner/GenezioSpinner";
import {
  deleteFrontendByCustomDomain,
  getFrontendByProjectId,
  getUserInformation,
  requestCustomDomain,
  restartFrontendDomainCron,
  getCurrentCollaboratorDetails,
} from "../../network/ApiAxios";
import FrontendElement from "../../views/Project/Frontend";
import { CollaboratorDetails } from "../../models/CollaborationModels";

const Frontend = () => {
  const [currentCollaboratorDetails, setCurrentCollaboratorDetails] = useState<CollaboratorDetails>({
    email: "",
    role: "",
  });
  const { projectId, envId } = useParams<{ projectId?: string; envId: string }>();
  const [frontendsList, setFrontendsList] = useState<any>([]);

  const [selectedFrontend, setSelectedFrontend] = useState<any>(null);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const updateDomain = useRef<boolean>(false);

  const [userInfo, setUserInfo] = useState<any>({}); // user info

  const [refreshUpdatedDomain, setRefreshUpdatedDomain] = useState<number>(0);

  useEffect(() => {
    async function asyncFetch() {
      setIsLoading(true);
      const resp: any = await getUserInformation();
      setIsLoading(false);

      if (resp.response && resp.response?.data?.error) {
        alert(resp.response.data.error.message);
        return;
      }

      if (resp.data && resp.data?.error) {
        alert(resp.data?.error?.message);
        return;
      }

      if (resp.data && resp.data.user) {
        setUserInfo(resp.data.user);
        return;
      }
    }
    asyncFetch();
  }, []);

  useEffect(() => {
    const runAsync = async () => {
      setIsLoading(true);
      const res: any = await getFrontendByProjectId(projectId ?? "", envId ?? "");
      if (res.data && res.data.status === "ok") {
        // start
        console.log(res.data, " res data");
        setFrontendsList(res.data.list);
        if (res.data.list.length > 0) {
          if (selectedFrontend === null) {
            setSelectedFrontend(res?.data?.list[0]);
          } else {
            const newSelectedFrontend = res.data.list.find(
              (frontend: any) =>
                (!(frontend.domainStatus === "CUSTOM_DOMAIN_VALIDATED")
                  ? frontend.genezioDomain + "." + process.env.REACT_APP_BASE_URL_FRONTEND_DOMAIN
                  : frontend.customDomain) ===
                (!(selectedFrontend.domainStatus === "CUSTOM_DOMAIN_VALIDATED")
                  ? selectedFrontend.genezioDomain + "." + process.env.REACT_APP_BASE_URL_FRONTEND_DOMAIN
                  : selectedFrontend.customDomain),
            );
            if (newSelectedFrontend) {
              setSelectedFrontend(newSelectedFrontend);
            } else {
              setSelectedFrontend(res.data.list[0]);
            }
          }
        } else {
          setSelectedFrontend(null);
        }
        // end
      } else {
        setFrontendsList([]);
        setSelectedFrontend(null);
        alert(res.data?.error?.message);
      }
      setIsLoading(false);
    };
    runAsync();
  }, [updateDomain.current, refreshUpdatedDomain]);

  const fetchCurrentCollaboratorDetails = async () => {
    const res: any = await getCurrentCollaboratorDetails(projectId || "");

    if (res.status === 200 && res.data.status === "ok") {
      setCurrentCollaboratorDetails(res.data);
    }
  };

  useEffect(() => {
    fetchCurrentCollaboratorDetails();
  }, []);

  useEffect(() => {
    document.title = "Genezio | Frontend";
  }, []);

  function refreshFrontends() {
    updateDomain.current = !updateDomain.current;
    setRefreshUpdatedDomain(refreshUpdatedDomain + 1);
  }

  if (isLoading) {
    return (
      <Card.Body className="pt-0">
        <Row className="pb-2">
          <Col sm={12} className="col-12">
            <GenezioSpinner />
          </Col>
        </Row>
      </Card.Body>
    );
  }

  if (selectedFrontend === null) {
    return (
      <Row className="mt-3">
        <Col sm={12}>
          <Card>
            <Card.Header className="pb-0">
              <h5 className="card-title" style={{ textTransform: "none" }}>
                Frontend
              </h5>
            </Card.Header>
            <Card.Body className="pt-0">
              <div>
                <Row className="pb-2">
                  <Col sm={12} className="col-12 text-center p-5">
                    <h4>Your frontend is not deployed!</h4>
                    <p className="mb-3 ">
                      Information about your frontend will be shown here after the first deployment.
                    </p>
                    <a
                      href={process.env.REACT_APP_GENEZIO_DOCUMENTATION + "features/frontend-deployment"}
                      target="_blank"
                      rel="noreferrer"
                      className="frontend-deply-btn-how-to-deploy p-1 px-2"
                    >
                      How to deploy a frontend
                    </a>
                  </Col>
                </Row>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  }

  return (
    <Row className="mt-3">
      <Col sm={12}>
        <Card>
          <Card.Header className="pb-0">
            <h5 className="card-title" style={{ textTransform: "none" }}>
              Frontend
            </h5>
          </Card.Header>
          <Card.Body className="pt-0">
            <React.Fragment>
              {selectedFrontend && (
                <FrontendElement
                  userInfo={userInfo}
                  refreshFrontends={refreshFrontends}
                  currentCollaboratorDetails={currentCollaboratorDetails}
                  frontend={selectedFrontend}
                />
              )}
            </React.Fragment>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default Frontend;
