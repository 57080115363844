import React from "react";
// import GettingStartedCode from "../components/GettingStartedCode";

export default function FastifyGettingStarted() {
  window.location.replace("/fork?repository=https://github.com/Genez-io/fastify-getting-started");

  return (
    <React.Fragment>
      {/* <GettingStartedCode
        projectName="fastify-getting-started"
        repository="https://github.com/Genez-io/fastify-getting-started"
        title="Fastify Getting Started"
        description="This is a simple Fastify template that will be deployed to the Genezio Cloud. Please provide a project name to get started."
        stack={["fastify"]}
      /> */}
    </React.Fragment>
  );
}
