import React from "react";
// import GettingStartedCode from "../components/GettingStartedCode";

export default function ExpressFullStackReact() {
  window.location.replace("/fork?repository=https://github.com/Genez-io/express-react-getting-started");
  return (
    <React.Fragment>
      {/* <GettingStartedCode
        projectName="express-fullstack-getting-started"
        repository="https://github.com/Genez-io/express-react-getting-started"
        title="Express Fullstack Getting Started"
        description="This is a fullstack Express.js application that will be deployed as a serverless function on the Genezio
      Cloud. Please provide a project name to get started."
        stack={["express"]}
      /> */}
    </React.Fragment>
  );
}
