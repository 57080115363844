import React from "react";
// import GettingStartedCode from "../components/GettingStartedCode";

export default function VueGettingStarted() {
  window.location.replace("/fork?repository=https://github.com/Genez-io/vue-getting-started");

  return (
    <React.Fragment>
      {/* <GettingStartedCode
        projectName="vue-getting-started"
        repository="https://github.com/Genez-io/vue-getting-started"
        title="Vue Getting Started"
        description="This is a simple Vue template that will be deployed to the Genezio Cloud. Please provide a project name to get started."
        stack={["vue"]}
      /> */}
    </React.Fragment>
  );
}
