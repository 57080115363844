import { useEffect, useState } from "react";
import { Card, Col, Row, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import {
  connectUserToGithub,
  checkIntegrationUser,
  getUserRepositories,
  getRepositoryDetails,
  linkRepository,
} from "../../network/ApiAxios";
import ConnectToGithub from "./Components/ConnectToGithubImportProject";
import ProjectConfiguration from "./Components/ProjectConfiguration";
import DeployProgress from "./Components/DeployProgress";
import Sidebar from "./Components/Sidebar";

const ImportProjectFlow = () => {
  const [isConnectedToGithub, setIsConnectedToGithub] = useState<boolean>(false);
  const [githubLoading, setGithubLoading] = useState<boolean>(false);
  const [githubReposLoading, setGithubReposLoading] = useState<boolean>(false);
  const [githubUserDetails, setGithubUserDetails] = useState<any>({});
  const [githubUserRepos, setGithubUserRepos] = useState<any>({});
  const [repoName, setRepoName] = useState<string>("");
  const [isDeploying, setIsDeploying] = useState<boolean>(false);
  const [settingsUrl, setSettingsUrl] = useState<string>("");
  const [repoBranch, setRepoBranch] = useState<string>("");
  const [projectConfiguration, setProjectConfiguration] = useState<any>(null);
  const [framework, setFramework] = useState<string>("");
  const [envVarsKeys, setEnvVarsKeys] = useState<string[]>([]);
  const [basePath, setBasePath] = useState<string>("./");

  const urlParams = new URLSearchParams(window.location.search);
  const codeId = urlParams.get("code") || "";
  const state = urlParams.get("state") || "";
  const installationId = urlParams.get("installation_id") || "";

  const params = useParams();
  const repoId: number = parseInt(params.id ?? "");

  const handleGithubConnect = async () => {
    if (!isConnectedToGithub) {
      setGithubLoading(true);

      if (codeId) {
        const res = await connectUserToGithub(codeId, installationId).catch((err) => {
          console.log(err);
          return null;
        });
        if (!res || res.status !== 200) {
          return;
        }
        if (state === "fork") {
          window.location.href = `/fork?${localStorage.getItem("locationSearch") || ""}`;
          return;
        }
        // remove code and state from the url
        window.history.replaceState({}, "", window.location.pathname);
      }

      const res: any = await checkIntegrationUser("github");
      if (res?.status === 200 && res?.data?.connection_status) {
        setGithubUserDetails(res.data?.connected_account);
        setIsConnectedToGithub(true);
      }
      setGithubLoading(false);
    }
  };

  const fetchUserRepos = async () => {
    if (isConnectedToGithub) {
      setGithubReposLoading(true);
      const res: any = await getUserRepositories(true);
      setGithubReposLoading(false);

      if (res.status === 200 && res.data.status === "ok") {
        setSettingsUrl(res.data.settingsUrl);
        setGithubUserRepos(res.data.repositories);
      } else {
        setGithubUserRepos([]);
        setIsConnectedToGithub(false);
        setGithubUserDetails({});
      }
    }
  };

  const handleDeployProject = async (
    framework: string,
    githubRepositoryId: any,
    path: any,
    projectConfigurationInput: any,
    env: any,
  ) => {
    const envs: { [key: string]: string } = {};
    env.forEach((e: any) => {
      envs[e.name] = e.value;
    });
    setProjectConfiguration((prev: any) => ({ ...prev, ...projectConfigurationInput }));

    const res: any = await linkRepository(framework, githubRepositoryId, path || ".", projectConfigurationInput, envs);
    setProjectConfiguration(projectConfigurationInput);

    if (res.status === 200 && res.data.status === "ok") {
      window.scrollTo({ top: 0, behavior: "smooth" });
      setIsDeploying(true);
    } else {
      console.log(res);
      alert(res.response.data.error.message);
    }
  };

  useEffect(() => {
    handleGithubConnect();
  }, [codeId]);

  useEffect(() => {
    fetchUserRepos();
  }, [isConnectedToGithub]);

  useEffect(() => {
    const handleRepoInfo = async () => {
      const repoDetails = await getRepositoryDetails(repoId, basePath);
      if (!repoDetails?.data) {
        alert("Repository not found");
        return;
      }
      setRepoName(`${repoDetails?.data?.repository?.owner}/${repoDetails?.data?.repository?.name}`);
      setRepoBranch(repoDetails?.data?.repository?.branch);
      setEnvVarsKeys(repoDetails?.data?.envKeys || []);
      const projectConfig = repoDetails?.data?.projectConfiguration || {};
      projectConfig.name = projectConfig.name || repoDetails?.data?.repository?.name;
      projectConfig.region = projectConfig.region || "us-east-1";
      setProjectConfiguration(projectConfig || {});
      setFramework(repoDetails.data.framework);
    };

    if (repoId) {
      handleRepoInfo();
    }
  }, [repoId, basePath]);

  return (
    <Row>
      <a href="/new-project" className="mt-4 mb-3 text-decoration-underline">
        {"<< "}Back to New Project
      </a>

      <Col sm={12} className="col-12">
        <Card>
          <Card.Body
            style={{ minHeight: "80vh", paddingTop: "20px" }}
            className="d-flex align-items-start justify-content-center w-100"
          >
            {!repoId ? (
              <ConnectToGithub
                githubUserDetails={githubUserDetails}
                isConnectedToGithub={isConnectedToGithub}
                githubUserRepos={githubUserRepos}
                connectMoreAccounts={() => setIsConnectedToGithub(false)}
                githubReposLoading={githubReposLoading}
                settingsUrl={settingsUrl}
              />
            ) : (
              <>
                {projectConfiguration ? (
                  <>
                    {isDeploying ? (
                      // Deploy Progress
                      <div className="w-100" style={{ minHeight: "80vh" }}>
                        <Row className="mt-4 w-100">
                          <Sidebar repoName={repoName} branch={repoBranch} activeStep={2} basePath={"./"} />
                          <DeployProgress githubRepositoryId={repoId} projectName={projectConfiguration.name} />
                        </Row>
                      </div>
                    ) : (
                      // Project configuration and deploy
                      <Row className="mt-4 w-100">
                        <Sidebar repoName={repoName} branch={repoBranch} activeStep={1} basePath={"./"} />
                        <ProjectConfiguration
                          key={`project-config-${repoId}-${basePath}-${framework}`}
                          githubRepositoryId={repoId}
                          basePath={basePath}
                          handleDeployProject={handleDeployProject}
                          projectConfiguration={projectConfiguration}
                          repoName={repoName}
                          frameworkProps={framework}
                          envVarsKeys={envVarsKeys}
                          handleChangeBasePath={(path: string) => {
                            console.log(path);
                            setBasePath(path);
                          }}
                        />
                      </Row>
                    )}
                  </>
                ) : (
                  <Spinner animation="border" variant="primary" />
                )}
              </>
            )}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default ImportProjectFlow;
