import React from "react";
// import GettingStartedCode from "../components/GettingStartedCode";

export default function ReactGettingStarted() {
  window.location.replace("/fork?repository=https://github.com/Genez-io/react-getting-started");

  return (
    <React.Fragment>
      {/* <GettingStartedCode
        projectName="react-getting-started"
        repository="https://github.com/Genez-io/react-getting-started"
        title="React Getting Started"
        description="This is a simple React template that will be deployed to the Genezio Cloud. Please provide a project name to get started."
        stack={["react"]}
      /> */}
    </React.Fragment>
  );
}
