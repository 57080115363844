import React from "react";
// import GettingStartedCode from "../components/GettingStartedCode";

export default function TypeSafeGettingStarted() {
  window.location.replace("/fork?repository=https://github.com/Genez-io/genezio-typesafe-getting-started");

  return (
    <React.Fragment>
      {/* <GettingStartedCode
        projectName="typesafe-getting-started"
        repository="https://github.com/Genez-io/genezio-typesafe-getting-started"
        title="Genezio Type-Safe Getting Started"
        description="This is a simple project using the Genezio Type-Safe framework. It exposes a simple function that returns a hello message, and a frontend that calls this function"
        stack={["typesafe"]}
      /> */}
    </React.Fragment>
  );
}
