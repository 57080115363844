import React, { useEffect, useState } from "react";
import { Col, Row, Card, Spinner, Dropdown, DropdownButton } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import Frontend from "../../views/Project/Frontend";
import { deleteProjectById, getProjectById, getCurrentCollaboratorDetails } from "../../network/ApiAxios";
import DeleteProject from "../../components/AdvancedUI/Modals/DeleteProject";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CollaboratorDetails } from "../../models/CollaborationModels";
import Backend from "../Backend/Backend";
import DeleteRepo from "../../components/AdvancedUI/Modals/DeleteRepo";
import { sendToast } from "../../views/Integrations/utils";

import { getLinkedRepositories, deleteLinkedRepository } from "../../network/ApiAxios";

export default function Project() {
  const { projectId, envId } = useParams<{ projectId?: string; envId: string }>();

  const navigate = useNavigate();

  const [project, setProject] = useState<any>({});
  const [classes, setClasses] = useState<any>([]);
  const [currentCollaboratorDetails, setCurrentCollaboratorDetails] = useState<CollaboratorDetails>({
    email: "",
    role: "",
  });

  const [activeEnv, setActiveEnv] = useState<any>({});

  const [modal, setModal] = useState<boolean>(false);
  const [unlinkRepoModal, setUnlinkRepoModal] = useState<boolean>(false);
  const [unlinkRepoLoading, setUnlinkRepoLoading] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState(true);

  const [isDeleting, setIsDeleting] = useState(false);

  const [error, setError] = useState<any>(null);

  const [linkedRepos, setLinkedRepos] = useState<any>([]);

  useEffect(() => {
    const runAsyncGetProjectInfo = async () => {
      const res: any = await getProjectById(projectId ?? "");
      if (res.data && res.data.status === "ok") {
        const localActiveEnv = res.data.project.projectEnvs.find((env: any) => env.id === envId);
        setActiveEnv(localActiveEnv);
        setProject(res.data.project);
        setClasses(localActiveEnv.classes);
        setIsLoading(false);
      } else if (res.response.data.error.code === 2 || res.response.data.error.code === 6) {
        navigate("/dashboard");
      }
    };

    runAsyncGetProjectInfo();
  }, []);

  async function handleDeleteProject() {
    setIsDeleting(true);

    const toastId = toast.success("Your project is being deleted, it might take up to 2 minutes", {
      position: "top-right",
      autoClose: false,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

    setError("This action may take a while. Please wait...");
    const res: any = await deleteProjectById(projectId ?? "");
    if (res.response && res.response.data && res.response.data.error) {
      setError(res.response.data.error.message);
      setIsDeleting(false);
      setError(null);
      return;
    }
    if (res.data.status === "ok") {
      navigate("/dashboard");
      toast.dismiss(toastId);
    } else {
      setError(res.data.message);
    }
    setIsDeleting(false);
  }

  const fetchCurrentCollaboratorDetails = async () => {
    const res: any = await getCurrentCollaboratorDetails(projectId || "");

    if (res.status === 200 && res.data.status === "ok") {
      setCurrentCollaboratorDetails(res.data);
    }
  };

  const fetchLinkedRepos = async () => {
    setUnlinkRepoLoading(true);
    const res: any = await getLinkedRepositories(projectId);
    setUnlinkRepoLoading(false);

    if (res.status === 200 && res.data.status === "ok") {
      setLinkedRepos(res.data.repositories);
      setUnlinkRepoModal(false);
      return;
    }

    if (res?.response?.data?.error.code !== 3) {
      sendToast(res?.response?.data?.error?.message || "Unable to fetch linked repositories", "error");
    }
  };

  const handleDeleteLinkedRepo = async (id: string) => {
    const res: any = await deleteLinkedRepository(id);

    if (res.status === 200 && res.data.status === "ok") {
      fetchLinkedRepos();
      return;
    }

    if (res?.response?.data?.error.code !== 3) {
      sendToast(res?.response?.data?.error?.message || "Unable to unlink repository", "error");
    }
  };

  useEffect(() => {
    document.title = "Genezio | Project Overview";
  }, []);

  useEffect(() => {
    fetchCurrentCollaboratorDetails();
  }, []);

  useEffect(() => {
    fetchLinkedRepos();
  }, []);

  return (
    <>
      <ToastContainer />

      {/* <!-- Modal --> */}
      <DeleteProject
        projectName={project.name}
        handleDeleteProject={() => handleDeleteProject()}
        show={modal}
        onHide={() => setModal(false)}
      />
      {/* <!-- /Modal --> */}

      {/* <!-- Modal --> */}
      <DeleteRepo
        show={unlinkRepoModal}
        unlinkRepoLoading={unlinkRepoLoading}
        linkedRepos={linkedRepos}
        onHide={() => setUnlinkRepoModal(false)}
        handleDeleteLinkedRepo={handleDeleteLinkedRepo}
      />
      {/* <!-- /Modal --> */}

      {/* <!-- row  --> */}
      <Row className="mt-3">
        <Col sm={12} className="col-12">
          <Card>
            <div>
              <Card.Header>
                <h4 className="card-title">Project Overview</h4>
              </Card.Header>
              <Card.Body className="pt-0">
                <Row className="mb-2 mt-2 pt-2 pb-2 border d-flex justify-content-between">
                  <div className="d-flex align-items-center pt-1">
                    <div className="me-3">
                      <h6 className="mb-0" style={{ color: "#6F42C1" }}>
                        Project:
                      </h6>
                    </div>
                    <div className="">
                      <p className="mb-0" style={{ color: "#6F42C1" }}>
                        {project.name ? project.name : <Spinner variant="primary" animation="border" size="sm" />}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center float-end pe-4">
                    <DropdownButton
                      size="sm"
                      id="dropdown-basic-button"
                      title="Actions"
                      variant="light"
                      autoClose={true}
                    >
                      <Dropdown.Item
                        disabled={currentCollaboratorDetails.role === "owner" ? false : true}
                        as="button"
                        onClick={() => setModal(true)}
                      >
                        Delete Project
                      </Dropdown.Item>
                      {linkedRepos?.length > 0 && (
                        <Dropdown.Item as="button" onClick={() => setUnlinkRepoModal(true)}>
                          Unlink Repository
                        </Dropdown.Item>
                      )}
                    </DropdownButton>
                  </div>
                </Row>

                <Row className="pb-2 pt-2">
                  <div className="d-flex align-items-center">
                    <div className="me-3">
                      <h6 className="mb-0">Region:</h6>
                    </div>
                    <div className="">
                      <p className="mb-0">
                        {project.region ? project.region : <Spinner variant="primary" animation="border" size="sm" />}
                      </p>
                    </div>
                  </div>
                </Row>
                <Row className="pb-2">
                  <div className="d-flex align-items-center">
                    <div className="me-3">
                      <h6 className="mb-0">Created:</h6>
                    </div>
                    <div>
                      {project.createdAt ? (
                        <p className="mb-0">{moment.unix(project.createdAt).format("MMM D, YYYY [at] h:mm A")}</p>
                      ) : (
                        <Spinner variant="primary" animation="border" size="sm" />
                      )}
                    </div>
                  </div>
                </Row>
                <Row className="pb-2">
                  <div className="d-flex align-items-center">
                    <div className="me-3">
                      <h6 className="mb-0">Updated:</h6>
                    </div>
                    <div>
                      {project.updatedAt ? (
                        <p className="mb-0">{moment.unix(project.updatedAt).format("MMM D, YYYY [at] h:mm A")}</p>
                      ) : (
                        <Spinner variant="primary" animation="border" size="sm" />
                      )}
                    </div>
                  </div>
                </Row>

                {linkedRepos?.length > 0 && (
                  <Row className="pb-2">
                    <Col>
                      <div className="d-flex align-items-start">
                        <div className="me-3">
                          <h6 className="mb-0">Repository:</h6>
                        </div>
                        <div>
                          {linkedRepos?.map((repo: any) => (
                            <a
                              href={`https://github.com/${repo?.owner}/${repo?.name}`}
                              className="mb-0 text-decoration-underline"
                              target="_blank"
                              rel="noreferrer"
                            >
                              {`https://github.com/${repo?.owner}/${repo?.name}`}
                            </a>
                          ))}
                        </div>
                      </div>
                    </Col>
                  </Row>
                )}
              </Card.Body>
            </div>
            {/* Domains */}
            <Frontend overview={true} currentCollaboratorDetails={currentCollaboratorDetails} />
            {/* Classes */}
            {activeEnv?.classes?.length > 0 && <Backend />}
            {/* Functions */}
            {activeEnv?.functions?.length > 0 && <Backend isFunction={true} />}
          </Card>
        </Col>
      </Row>
    </>
  );
}
