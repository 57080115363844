import { useState, useEffect } from "react";
import { Card, Form, Breadcrumb, Row, Col, Button, Spinner, Alert, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import ImportVariables from "../components/AdvancedUI/Modals/ImportVariables";
import CancelEnv from "../components/AdvancedUI/Modals/CancelEnv";
import DeleteEnv from "../components/AdvancedUI/Modals/DeleteEnv";
import {
  getProjectEnv,
  createProjectEnv,
  deleteProjectEnv,
  getProjectById,
  getProjectIntegrations,
  getCurrentCollaboratorDetails,
} from "../network/ApiAxios";
import { toast, ToastContainer } from "react-toastify";
import { defaultReservedEnvNames } from "../utils";
import { CollaboratorDetails } from "../models/CollaborationModels";

interface RouteParams {
  projectId?: string;
  projectName?: string;
  [key: string]: string | undefined;
}

const tooltip = (
  <Tooltip id="tooltip-top">You are not able to edit or delete variables set from an integration.</Tooltip>
);

const EnvironmentVariables = () => {
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [env, setEnv] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmited, setIsSubmited] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [refreshModal, setRefreshModal] = useState(false);
  const [cancelEnvModal, setCancelEnvModal] = useState(false);
  const [project, setProject] = useState<any>({});
  const [edit, setEdit] = useState<any>([]);

  const [activeEnv, setActiveEnv] = useState<any>({});
  const [selectedEnv, setSelectedEnv] = useState<any>();
  const { projectId, envId } = useParams<RouteParams>();
  const [restrictedEnvVars, setRestrictedEnvVars] = useState(defaultReservedEnvNames);
  const [currentCollaboratorDetails, setCurrentCollaboratorDetails] = useState<CollaboratorDetails>({
    email: "",
    role: "",
  });

  const navigate = useNavigate();

  const sendToastNotification = (str: string, type: string) => {
    if (type === "error") {
      toast.error(str, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.success(str, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const fetchCurrentCollaboratorDetails = async () => {
    const res: any = await getCurrentCollaboratorDetails(projectId || "");

    if (res.status === 200 && res.data.status === "ok") {
      setCurrentCollaboratorDetails(res.data);
    }
  };

  // Retrieve stored environments and push an empty object to display the create environment inputs.
  const fetchData = async () => {
    const res: any = await getProjectEnv(projectId ?? "", envId ?? "");

    res.data.environmentVariables = res.data.environmentVariables;

    // Get unique values between stored and non-sorted variables.
    const uniqueData: Array<any> = [];
    env?.forEach((elem: any) => {
      if (!elem.id && !res?.data?.environmentVariables.some((obj: any) => obj.name === elem.name)) {
        uniqueData.push(elem);
      }
    });

    // Check if we have one empty object in our array for the add field
    const hasEmptyObject = uniqueData.some((obj) => Object.keys(obj).length === 0);
    if (!hasEmptyObject) {
      uniqueData.push({});
    }

    setEnv([...res?.data?.environmentVariables, ...uniqueData]);
  };

  // Handle inputs change for env page
  const handleInputChange = (idx: number, field: string, value: string) => {
    setRefreshModal(true);

    const updatedFileContent = [...env];
    updatedFileContent[idx][field] = value;
    setEnv(updatedFileContent);

    // Check if we type in create new env input and if we do push a new empty input
    const lastEnv = env[env.length - 1];
    if (lastEnv?.name) {
      setEnv([...env, {}]);
    }
  };

  function parseEnv(src: string) {
    const LINE =
      /(?:^|^)\s*(?:export\s+)?([\w.-]+)(?:\s*=\s*?|:\s+?)(\s*'(?:\\'|[^'])*'|\s*"(?:\\"|[^"])*"|\s*`(?:\\`|[^`])*`|[^#\r\n]+)?\s*(?:#.*)?(?:$|$)/gm;
    const arr: any = [];
    // Convert buffer to string
    let lines = src.toString();
    // Convert line breaks to same format
    lines = lines.replace(/\r\n?/gm, "\n");
    let match;
    while ((match = LINE.exec(lines)) != null) {
      const key = match[1];
      // Default undefined or null to empty string
      let value = match[2] || "";
      // Remove whitespace
      value = value.trim();
      // Check if double quoted
      const maybeQuote = value[0];
      // Remove surrounding quotes
      value = value.replace(/^(['"`])([\s\S]*)\1$/gm, "$2");
      // Expand newlines if double quoted
      if (maybeQuote === '"') {
        value = value.replace(/\\n/g, "\n");
        value = value.replace(/\\r/g, "\r");
      }
      // Push to array
      arr.push({ name: key, value: value });
    }

    return arr;
  }

  const handlePaste = (event: any, idx: any) => {
    const data = event.clipboardData.getData("text");

    if (!data.includes("=")) {
      return;
    }
    const parsedData = parseEnv(data);
    if (env[idx].name) {
      return;
    }

    // if (!data.includes("=")) {
    //   return;
    // }

    setRefreshModal(true);
    event.preventDefault();

    const updatedFileContent = env.filter((obj: any) => Object.keys(obj).length > 0);

    setEnv([...updatedFileContent, ...parsedData, {}]);
  };

  //check if all names are unqiue
  function areAllNamesUnique(name: any) {
    if (name !== "") {
      const index = env.findIndex((obj: any) => obj.name === name);
      const occurrences = env.filter((obj: any) => obj.name === name);
      return {
        found: occurrences.length > 1 ? true : false,
        isValid: occurrences[0]?.name?.length > 1 ? true : false,
        index,
      };
    } else {
      const names = env
        .filter((obj: any) => obj.hasOwnProperty("name")) // Filter out objects without the 'name' key
        .map((obj: any) => obj.name);
      const uniqueNames = new Set(names);

      for (var i = 0; i < names.length; i++) {
        const name = names[i];
        if (name?.length < 2) {
          return false;
        }
      }

      return names.length === uniqueNames.size;
    }
  }

  // This function filters the body being sent to the "createProjectEnv" request by removing stored environments. For edits, it removes the "lastAccessedAt" and "id" keys from the object to pass backend validation.
  const handleSave = async () => {
    setIsSubmited(true);

    // Check if names are unique
    function checkNames(arr: any[]): boolean {
      const names = arr.map((elem: any) => elem.name);
      const uniqueNames = new Set(names);
      return arr.length === uniqueNames.size;
    }
    if (!checkNames(env)) {
      sendToastNotification("Name must be unique", "error");
    }

    // Check if names are valid
    if (
      !env
        .filter((elem: any) => elem.hasOwnProperty("name"))
        .map((elem: any) => elem.name?.length >= 2)
        .every(Boolean)
    ) {
      sendToastNotification("Name must be at least 2 characters", "error");
    }

    if (areAllNamesUnique("") && refreshModal) {
      setIsLoading(true);
      const filteredEnv = env
        .filter((obj: { value?: string }) => obj.value !== undefined && obj.value !== "")
        .map((obj: { value?: string; lastAccessedAt?: any; id?: any }) => {
          const { lastAccessedAt, id, ...rest } = obj;
          return rest;
        });

      const res: any = await createProjectEnv(projectId ?? "", envId ?? "", filteredEnv);

      fetchData();
      setIsLoading(false);
      setIsSubmited(false);
      setRefreshModal(false);

      if (res.status >= 200 && res.status < 300) {
        sendToastNotification("Environment variables saved", "");
      } else {
        sendToastNotification(res.response.data.error.message, "error");
      }
      setEdit([]);
    }
  };

  // delete env
  const handleDelete = async () => {
    setIsDeleting(true);
    // Check if my environment is a stored one or a new one that is only in my React state
    const isNewEnv = selectedEnv?.split("-")[0] === "new" ? true : false;
    if (isNewEnv) {
      // Here is the logic for non-stored variables and we filter them without changing the sorting of the array
      setEnv((prevEnv: any[]) => {
        return prevEnv.filter((_, i) => i != selectedEnv?.split("-")[1]);
      });
      setDeleteModal(false);
    } else {
      // Here we remove a stored variable
      await deleteProjectEnv(projectId ?? "", envId ?? "", selectedEnv);
      fetchData();
    }
    setIsDeleting(false);
    sendToastNotification("Environment variable deleted", "");
  };

  const fetchActiveIntegrations = async () => {
    const res: any = await getProjectIntegrations(projectId ?? "", envId ?? "");
    if (res?.status === 200) {
      // setReservedEnvs(res?.data?.integrations);
    }
  };

  const setReservedEnvs = (integrationsEnv: string[]) => {
    for (const env of integrationsEnv) {
      switch (env) {
        case "NEON-POSTGRES":
          setRestrictedEnvVars((prevState) => [...prevState, "NEON_POSTGRES_URL"]);
          break;
        case "SENTRY":
          setRestrictedEnvVars((prevState) => [...prevState, "SENTRY_DSN"]);
          break;
        case "UPSTASH-REDIS":
          setRestrictedEnvVars((prevState) => [
            ...prevState,
            "UPSTASH_REDIS_URL",
            "UPSTASH_REDIS_REST_URL",
            "UPSTASH_REDIS_REST_TOKEN",
          ]);
          break;
      }
    }
  };

  // disable save button if we have reserved key or duplicate key
  const isSaveBtnDisabled = () => {
    const restrictedNames = new Set();
    const duplicateNames = new Set();

    for (const obj of env) {
      if (obj.name) {
        if (restrictedEnvVars.includes(obj.name)) {
          restrictedNames.add(obj.name);
        }
        if (duplicateNames.has(obj.name)) {
          return true; // Name is a duplicate
        }
        duplicateNames.add(obj.name);
      }
    }

    return restrictedNames.size > 0; // Return true if restricted names were found
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchActiveIntegrations();
  }, []);

  useEffect(() => {
    document.title = "Genezio | Env Variables";
  }, []);

  useEffect(() => {
    fetchCurrentCollaboratorDetails();
  }, []);

  useEffect(() => {
    const runAsync = async () => {
      const res: any = await getProjectById(projectId ?? "");
      if (res.data && res.data.status === "ok") {
        const localActiveEnv = res.data.project.projectEnvs.find((env: any) => env.id === envId);
        setActiveEnv(localActiveEnv);
        setProject(res.data.project);
        setIsLoading(false);
      } else if (res.response.data.error.code === 2 || res.response.data.error.code === 6) {
        navigate("/dashboard");
      }
    };
    runAsync();
  }, []);

  const handleBeforeUnload = (event: BeforeUnloadEvent) => {
    event.preventDefault();
    event.returnValue = "";
  };

  // Refresh modal
  useEffect(() => {
    if (refreshModal) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    }
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [refreshModal]);

  return (
    <>
      {/* .env Modal */}
      <ImportVariables
        modal={modal}
        storedEnvs={env}
        handleImport={(envs) => {
          setEnv(envs);
          setRefreshModal(true);
        }}
        onHide={() => setModal(false)}
      />

      <CancelEnv
        projectId={projectId || ""}
        envId={envId || ""}
        hide={() => setCancelEnvModal(false)}
        show={cancelEnvModal}
      />

      <ToastContainer />

      {/* Ennironment Variables Card Content */}
      <Row className="mt-3">
        {currentCollaboratorDetails.role === "collaborator" && activeEnv.name === "prod" ? (
          <Col sm={12}>
            <Alert variant="warning text-muted">You are unable to view environment variables as a collaborator</Alert>
          </Col>
        ) : (
          <Col sm={12}>
            <Card style={{ padding: "0 0 59px 14px", minHeight: "75vh" }}>
              <Card.Header>
                <h4 className="card-title">ENVIRONMENT VARIABLES</h4>
                {project?.name &&
                  !project?.projectEnvs?.filter((project: any) => project.id === envId)[0]?.classes?.length &&
                  !project?.projectEnvs?.filter((project: any) => project.id === envId)[0]?.functions?.length && (
                    <Alert variant="warning text-muted" className="mb-0 text-muted">
                      Environment variables can be set only if your backend contains classes or functions. This project
                      has not deployed any classes or functions, so setting environment variables will have no effect
                      now.
                    </Alert>
                  )}
                <Button
                  className="py-1 px-3 bg-transparent my-3"
                  variant="outline-primary"
                  onClick={() => setModal(true)}
                  disabled={isLoading}
                >
                  Import from .env
                </Button>
              </Card.Header>
              <Card.Body className="pt-0">
                {/* Table Header */}
                <Row className="mb-2 font-weight-bold w-100">
                  <Col lg={4}>
                    <span style={{ marginRight: "10px" }}>#</span> Key
                  </Col>
                  <Col style={{ marginLeft: "10px" }} lg={7}>
                    Value
                  </Col>
                </Row>
                {/* Table Data */}
                {env?.map((elem: any, idx: number) => (
                  <Row key={elem?.id || idx} className="mb-3 d-flex">
                    {(deleteModal && selectedEnv === elem?.id) || (deleteModal && selectedEnv?.split("-")[1] == idx) ? (
                      <DeleteEnv
                        isDeleting={isDeleting}
                        handleDelete={() => handleDelete()}
                        envName={elem?.name}
                        isStored={elem?.id ? true : false}
                        onHide={() => {
                          setDeleteModal(false);
                          setSelectedEnv("");
                        }}
                      />
                    ) : (
                      ""
                    )}
                    <Col lg={4} className="d-flex">
                      <div style={{ minWidth: "25px" }}>
                        <p className="mb-0 pt-2">{idx + 1}</p>
                      </div>
                      <Form.Group as={Col} controlId="validationCustom03">
                        <Form.Control
                          style={{ height: "35px" }}
                          className="form-control"
                          type="text"
                          value={elem?.name ? elem?.name : ""}
                          disabled={elem?.id || currentCollaboratorDetails.role === "collaborator" ? true : false}
                          onChange={(e) => handleInputChange(idx, "name", e.target.value)}
                          onPaste={(e) => handlePaste(e, idx)}
                          required={elem?.value && !elem?.name ? true : false}
                          isInvalid={
                            restrictedEnvVars.includes(elem.name)
                              ? true
                              : (
                                    areAllNamesUnique(elem?.name) as {
                                      found: boolean;
                                      index: any;
                                    }
                                  )?.found === true ||
                                  (isSubmited &&
                                    elem.hasOwnProperty("name") &&
                                    !(areAllNamesUnique(elem?.name) as { isValid: any })?.isValid)
                                ? true
                                : false
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {restrictedEnvVars.includes(elem.name)
                            ? "Reserved env name"
                            : (
                                areAllNamesUnique(elem?.name) as {
                                  found: boolean;
                                  index: any;
                                }
                              )?.found === true &&
                              !restrictedEnvVars.includes(elem.name) &&
                              "Duplicate env name"}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col className="d-flex" lg={8}>
                      <Form.Control
                        style={{ height: "28px" }}
                        as="textarea"
                        className="form-control overflow-hidden"
                        value={elem?.value ? elem?.value : ""}
                        placeholder={elem?.id && !edit.includes(elem?.id) ? "**********" : ""}
                        onChange={(e) => handleInputChange(idx, "value", e.target.value)}
                        required={idx === env.length - 1 || (elem?.name && elem?.id) ? false : true}
                        disabled={
                          currentCollaboratorDetails.role === "collaborator" || (elem?.id && !edit.includes(elem?.id))
                            ? true
                            : false
                        }
                      />

                      {Object.keys(elem).length > 0 && elem?.id && (
                        <OverlayTrigger placement="top" overlay={elem?.type === "system" ? tooltip : <></>}>
                          <span>
                            <Button
                              variant="outline-info"
                              className="p-0"
                              disabled={
                                currentCollaboratorDetails.role === "collaborator" || elem?.type === "system"
                                  ? true
                                  : false
                              }
                              style={{
                                height: "30px",
                                maxWidth: "33px",
                                marginLeft: "20px",
                                width: "33px",
                              }}
                              onClick={() => {
                                if (elem?.id && !edit.includes(elem?.id)) {
                                  setEdit([...edit, elem?.id]);
                                } else {
                                  const filteredArray = edit.filter((id: any) => id !== elem?.id);
                                  delete elem.value;
                                  setEdit(filteredArray);
                                }
                              }}
                            >
                              {elem?.id && !edit.includes(elem?.id) ? (
                                <i className="fas fa-pencil-alt"></i>
                              ) : (
                                <i className="fas fa-times"></i>
                              )}
                            </Button>
                          </span>
                        </OverlayTrigger>
                      )}

                      {Object.keys(elem).length > 0 && (
                        <OverlayTrigger placement="top" overlay={elem?.type === "system" ? tooltip : <></>}>
                          <span>
                            <Button
                              variant="outline-danger"
                              className="p-0"
                              style={{
                                height: "30px",
                                maxWidth: "33px",
                                marginRight: "20px",
                                marginLeft: "20px",
                                width: "100%",
                              }}
                              disabled={
                                currentCollaboratorDetails.role === "collaborator" || elem?.type === "system"
                                  ? true
                                  : false
                              }
                              onClick={() => {
                                setDeleteModal(true);
                                setSelectedEnv(elem.id ? elem.id : `new-${idx}`);
                              }}
                            >
                              <i className="far fa-trash-alt" />
                            </Button>
                          </span>
                        </OverlayTrigger>
                      )}
                    </Col>
                  </Row>
                ))}
                {env && (
                  <p>
                    <b>TIP:</b> Paste .env content above to automatically fill in the form
                  </p>
                )}
              </Card.Body>

              {/* Buttons */}
              <Row className="mt-4 m-1 d-flex justify-content-end" style={{ paddingRight: "53px" }}>
                <Col lg={3} className="d-flex justify-content-end">
                  <Button
                    onClick={() =>
                      refreshModal ? setCancelEnvModal(true) : navigate(`/project/${projectId}/${envId}/`)
                    }
                    className="border rounded env-vars-btn"
                    disabled={isLoading}
                  >
                    Cancel
                  </Button>
                  <Button
                    form="envForm"
                    disabled={
                      isLoading || isSaveBtnDisabled() || currentCollaboratorDetails.role === "collaborator"
                        ? true
                        : false
                    }
                    variant="outline-success"
                    className="py-1 px-4"
                    style={{ marginLeft: "20px" }}
                    onClick={(e) => {
                      e.preventDefault();
                      handleSave();
                    }}
                  >
                    Save {isLoading && <Spinner animation="border" className="ms-2" size="sm" />}
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        )}
      </Row>
    </>
  );
};

export default EnvironmentVariables;
