import { Card, Col, Container, Modal, Row } from "react-bootstrap";
import "./GettingStarted.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import InfoTooltip from "../Tooltips/InfoTooltip";

const getStartedItems = [
  {
    title: "Serverless Function",
    description: "Enjoy 4x faster response times without cloud configurations.",
    image: require("../../assets/img/getting-started/lambda.png"),
    href: "/faas-getting-started",
    soon: false,
    modalName: "",
  },
  {
    title: "Express.js App",
    description: "Cut deployment and server costs by up to 6x.",
    image: require("../../assets/img/getting-started/nodejs.png"),
    href: "/express-getting-started",
    soon: false,
    modalName: "",
  },
  {
    title: "Typesafe Project",
    description: "Starter template for full-stack TypeScript projects.",
    image: require("../../assets/img/brand/icon-black.svg").default,
    href: "/typesafe-getting-started",
    soon: false,
    modalName: "",
  },
  {
    title: "Next.js App",
    description: "Eliminate unpredictable costs and cold start times.",
    image: require("../../assets/img/getting-started/nextjs.png"),
    href: "/nextjs-getting-started",
    soon: false,
    modalName: "",
  },
  {
    title: "HTML Starter",
    description: "Simple HTML template to get started.",
    image: require("../../assets/img/getting-started/html.png"),
    href: "/html-getting-started",
    soon: false,
    modalName: "",
  },
  {
    title: "React App Template",
    description: "Simple React template to get started.",
    image: require("../../assets/img/getting-started/react.png"),
    href: "/react-getting-started",
    soon: false,
    modalName: "",
  },
  {
    title: "Angular Getting Started",
    description: "Simple Angular template to get started.",
    image: require("../../assets/img/getting-started/angular.png"),
    href: "/angular-getting-started",
    soon: false,
    modalName: "",
  },
  {
    title: "Express.js Fullstack App",
    description: "Simple Express.js fullstack template to get started.",
    image: require("../../assets/img/getting-started/nodejs.png"),
    href: "/express-fullstack-getting-started",
    soon: false,
    modalName: "",
  },
  {
    title: "Vue Getting Started",
    description: "Simple Vue template to get started.",
    image: require("../../assets/img/getting-started/vue.png"),
    href: "/vue-getting-started",
    soon: false,
    modalName: "",
  },
  {
    title: "Fastify Getting Started",
    description: "Simple Fastify template to get started.",
    image: require("../../assets/img/getting-started/fastify.png"),
    href: "/fastify-getting-started",
    soon: false,
    modalName: "",
  },

];

const getStartedTemplates = [
  {
    title: "QR code generation ",
    description: "Fully functional web app with db integration.",
    image: require("../../assets/img/getting-started/templates/qrgen.png"),
    href: "/faas-getting-started",
    labels: ["Node.js", "React", "MongoDB"],
    byLogo: require("../../assets/img/brand/logo.svg").default,
  },
  {
    title: "QR code generation ",
    description: "Fully functional web app with db integration.",
    image: require("../../assets/img/getting-started/templates/qrgen.png"),
    href: "/faas-getting-started",
    labels: ["Node.js", "React", "MongoDB"],
    byLogo: require("../../assets/img/brand/logo.svg").default,
  },
  {
    title: "QR code generation ",
    description: "Fully functional web app with db integration.",
    image: require("../../assets/img/getting-started/templates/qrgen.png"),
    href: "/faas-getting-started",
    labels: ["Node.js", "React", "MongoDB"],
    byLogo: require("../../assets/img/brand/logo.svg").default,
  },
];

const getStartedTutorials = [
  {
    title: "Task automation through cron jobs",
    image: require("../../assets/img/getting-started/tutorials/cron.png"),
    href: "/cron-getting-started",
  },
  {
    title: "WhatsApp API Chatbot",
    image: require("../../assets/img/getting-started/tutorials/whatsapp.png"),
    href: "https://genezio.com/blog/whatsapp-genezio-project/",
  },
  {
    title: "Starter app on top of ChatGPT",
    image: require("../../assets/img/getting-started/tutorials/gpt.png"),
    href: "/chatgpt-getting-started",
  },
  {
    title: "Live data web scraping app",
    image: require("../../assets/img/getting-started/tutorials/scraping.png"),
    href: "/web-scraper-getting-started",
  },
];

interface GettingStartedProps {
  onboardingInfo?: any;
}

// eslint-disable-next-line no-empty-pattern
const GettingStarted: React.FC<GettingStartedProps> = (
  {
    // onboardingInfo
  },
) => {
  const [modal, setModal] = useState("");

  const navigate = useNavigate();
  const isDarkMode = localStorage.getItem("darkMode") === "true";
  useEffect(() => {
    // Create the script element
    const script = document.createElement("script");
    script.src = "//js-eu1.hsforms.net/forms/embed/v2.js";
    script.charset = "utf-8";
    script.type = "text/javascript";

    // Append the script to the body
    document.body.appendChild(script);

    // Function to create the form
    const createForm = () => {
      if ((window as any).hbspt) {
        (window as any).hbspt.forms.create({
          region: "eu1",
          portalId: modal === "next" ? "26189618" : "26189618",
          formId: modal === "next" ? "a7702987-4b9c-4c7c-b710-dd7005f89003" : "75a780e0-e07a-4d71-b464-a50f25cf1923",
          target: "#hubspotForm",
          onFormReady: (form: any) => {
            const firstInput = form.querySelector("input") as HTMLInputElement;
            const user: any = localStorage.getItem("user");
            if (firstInput && user) {
              firstInput.value = JSON.parse(user)?.email;
              firstInput.focus();
            }

            const submitButton = form.querySelector('input[type="submit"]');
            if (submitButton) {
              submitButton.style.float = "right";
              submitButton.style.marginLeft = "auto";
              form.style.padding = "20px 0 20px 0";
            }

            console.log(submitButton, " submitButton");
          },
        });
      }
    };
    // Check if the script is already loaded
    if ((window as any).hbspt) {
      createForm();
    } else {
      script.onload = createForm;
    }
    // Cleanup function to remove the script
    return () => {
      document.body.removeChild(script);
    };
  }, [modal]);

  return (
    <>
      <Modal show={modal === "" ? false : true} onHide={() => setModal("")} className="smaller-modal-width" centered>
        <Modal.Body style={{ padding: "30px" }}>
          <div className="d-flex justify-content-between">
            <h6 className="mb-3" style={{ fontWeight: "600" }}>
              Join Next.js Waiting List
            </h6>
          </div>
          <div id="hubspotForm"></div>
        </Modal.Body>
      </Modal>

      <Container fluid>
        {/* START GETTING STARTED */}
        <Card.Header className="ps-0 pb-0 d-flex">
          <h4 className="card-title">DEPLOY YOUR PROJECT</h4>{" "}
          <InfoTooltip>
            <a
              href="https://genezio.com/docs/learn-more/supported-languages"
              target="_blank"
              className="text-black text-decoration-underline"
              rel="noreferrer"
            >
              See supported languages and frameworks
            </a>
          </InfoTooltip>
        </Card.Header>
        <Row>
          <Col sm={12}>
            <button
              onClick={() => navigate("/import")}
              className={`btn ${
                isDarkMode ? "github-new-project-btn-light" : "github-new-project-btn"
              }  github-new-project-btn-sm mt-3 mb-4`}
            >
              <i className="fab fa-github"></i>
              <span>Import from Github</span>
            </button>

            <h6 className="fw-bold text-dark">Or get started with a template...</h6>
            <p>Here are some popular templates to help you get started. What would you like to build?</p>
            <Row>
              <Col sm={12}></Col>
              {getStartedItems.map((item, index) => (
                <Col key={index} sm={6} md={3} className="d-flex p-0 my-2">
                  <a
                    href={item.href}
                    className="w-100"
                    onClick={(e) => {
                      e.preventDefault();
                      if (item.soon) {
                        setModal(item.modalName);
                      } else if (item.href !== "#") {
                        if (item.href.startsWith("http") || item.href.startsWith("https")) {
                          window.open(item.href, "_blank");
                        } else {
                          navigate(item.href);
                        }
                      }
                    }}
                  >
                    <div className="card-elem-wrapper-template mx-2 p-3 d-flex">
                      <Row className="w-100">
                        <Col className="col-3 d-flex justify-content-center align-items-center align-content-center px-2">
                          <img src={item.image} alt="Genezio Logo" className="img-fluid" />
                        </Col>
                        <Col className="col-9 card-elem-text-wrapper ps-3 pe-0 d-flex flex-column justify-content-center">
                          <p className="mb-1 template-card-title">{item.title}</p>
                          <p className="mb-0 template-card-description">{item.description}</p>
                        </Col>
                      </Row>
                    </div>
                  </a>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
        {/* END GETTING STARTED */}

        {/* START TEMPLATES */}
        {/* <Card.Header className="ps-0 pb-0">
          <h4 className="card-title">TEMPLATES</h4>
        </Card.Header>
        <Row>
          <Col sm={12}>
            <Row>
              <Col sm={12}></Col>
              {getStartedTemplates.map((item, index) => (
                <Col sm={6} md={2} key={index} className="d-flex p-0 my-2">
                  <a
                    href={item.href}
                    className="w-100"
                    onClick={(e) => {
                      e.preventDefault();
                      if (item.href !== "#") {
                        if (item.href.startsWith("http") || item.href.startsWith("https")) {
                          window.open(item.href, "_blank");
                        } else {
                          navigate(item.href);
                        }
                      }
                    }}
                  >
                    <div className="card-elem-wrapper-template mx-2 p-3 d-flex justify-content-center">
                      <Row className="w-100">
                        <Col className="col-12 d-flex justify-content-center align-items-center align-content-center px-0">
                          <img src={item.image} alt="Genezio Logo" className="img-fluid template-img w-100" />
                        </Col>
                        <Col className="col-12 d-flex flex-column justify-content-center mt-2 px-0">
                          <p className="mb-2 template-card-title">{item.title}</p>
                          <p className="mb-0 template-card-description">{item.description}</p>
                          <div className="d-flex flex-wrap justify-content-start mt-3">
                            {item.labels.map((label) => (
                              <Badge bg="light" className="me-2 mt-1">
                                {label}
                              </Badge>
                            ))}
                          </div>
                          <div className="d-flex justify-content-start mt-2 align-items-center mt-3">
                            by <img src={item.byLogo} alt="Genezio Logo" className="img-fluid by-logo ms-1" />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </a>
                </Col>
              ))}
            </Row>
          </Col>
        </Row> */}
        {/* END TEMPLATES */}

        {/* START TUTORIALS */}
        <Card.Header className="ps-0 pb-0">
          <h4 className="card-title">TUTORIALS</h4>
        </Card.Header>
        <Row>
          <Col sm={10}>
            <Row>
              <Col sm={12}></Col>
              {getStartedTutorials.map((item, index) => (
                <Col key={index} sm={6} md={6} className="d-flex p-0 my-2">
                  <a
                    href={item.href}
                    className="w-100"
                    onClick={(e) => {
                      e.preventDefault();
                      if (item.href !== "#") {
                        if (item.href.startsWith("http") || item.href.startsWith("https")) {
                          window.open(item.href, "_blank");
                        } else {
                          navigate(item.href);
                        }
                      }
                    }}
                  >
                    <div className="card-elem-wrapper-template mx-2 p-3 d-flex">
                      <Row className="w-100">
                        <Col
                          xl={2}
                          md={2}
                          className="col-3 d-flex justify-content-center align-items-center align-content-center px-2"
                        >
                          <img src={item.image} alt="Genezio Logo" className="img-fluid" />
                        </Col>
                        <Col className="col-9 card-elem-text-wrapper ps-4 d-flex flex-column justify-content-center">
                          <p className="mb-0 template-card-title">{item.title}</p>
                        </Col>
                      </Row>
                    </div>
                  </a>
                </Col>
              ))}
            </Row>
          </Col>
          <Col className="col-12">
            <a
              href="https://genezio.com/tags/tutorials/"
              className="d-flex justify-content-start mt-2"
              target="_blank"
              rel="noreferrer"
            >
              See more tutorials <i className="fas fa-arrow-right ms-1 d-flex align-items-center"></i>
            </a>
          </Col>
        </Row>
        {/* END TUTORIALS */}
      </Container>
    </>
  );
};

export default GettingStarted;
