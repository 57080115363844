import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Spinner, Col, Row, Card, Button, Dropdown, Alert } from "react-bootstrap";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import {
  getClassById,
  getLogs,
  getFunctionLogs,
  getProjectById,
  getCurrentCollaboratorDetails,
} from "../network/ApiAxios";
import GenezioSpinner from "../components/Utilities/Spinner/GenezioSpinner";
import { copyToClipboard } from "../utils";
import { CollaboratorDetails } from "../models/CollaborationModels";

const MESSAGES_TYPES = ["START", "ALL", "ERROR", "REPORT", "INFO", "DEBUG", "WARNING", "END"];

const Lambda = () => {
  let navigate = useNavigate();
  const timeNow = new Date().getTime();
  const darkMode = localStorage.getItem("darkMode") === "true";

  const { classId, projectId, envId } = useParams();

  const [project, setProject] = React.useState<any>({});
  const [activeEnv, setActiveEnv] = React.useState<any>({});

  const [searchTerm, setSearchTerm] = React.useState("");
  const [logPeriod, setLogPeriod] = React.useState("30m");
  const [startTime, setStartTime] = React.useState(timeNow - 30 * 60 * 1000); //30mins ago
  const [endTime, setEndTime] = React.useState(timeNow);

  const [logs, setLogs] = React.useState<any>([]);
  const [nextToken, setNextToken] = React.useState(null);

  const [isLoading, setIsLoading] = React.useState(false);

  const [triggerFilter, setTriggerFilter] = React.useState(false);

  const [classInfo, setClassInfo] = React.useState<any>({});

  const [disableLoadMore, setDisableLoadMore] = React.useState(false);

  const [error, setError] = React.useState("");
  const [logsError, setLogsError] = React.useState<any>("");

  const [isChangingStatus, setIsChangingStatus] = React.useState(false);

  const [buttonCopyState, setButtonCopyState] = React.useState<string>("");

  const [currentCollaboratorDetails, setCurrentCollaboratorDetails] = React.useState<CollaboratorDetails>({
    email: "",
    role: "",
  });

  const [isFunction, setIsFunction] = React.useState<boolean>(false);

  function testProject() {
    navigate(`/test-interface/${projectId}/${envId}`);
  }

  const getTimeFromPeriod = () => {
    const timeNow = new Date().getTime();
    switch (logPeriod) {
      case "1m":
        return {
          localStartTime: timeNow - 60 * 1000,
          localEndTime: timeNow,
        };
      case "5m":
        return {
          localStartTime: timeNow - 5 * 60 * 1000,
          localEndTime: timeNow,
        };
      case "10m":
        return {
          localStartTime: timeNow - 10 * 60 * 1000,
          localEndTime: timeNow,
        };
      case "30m":
        return {
          localStartTime: timeNow - 30 * 60 * 1000,
          localEndTime: timeNow,
        };
      case "1h":
        return { localStartTime: timeNow - 3600 * 1000, localEndTime: timeNow };
      case "12h":
        return {
          localStartTime: timeNow - 12 * 3600 * 1000,
          localEndTime: timeNow,
        };
      case "24h":
        return {
          localStartTime: timeNow - 24 * 3600 * 1000,
          localEndTime: timeNow,
        };
      case "48h":
        return {
          localStartTime: timeNow - 48 * 3600 * 1000,
          localEndTime: timeNow,
        };
      case "72h":
        return {
          localStartTime: timeNow - 72 * 3600 * 1000,
          localEndTime: timeNow,
        };
      default:
        return { localStartTime: 0, localEndTime: 0 };
    }
  };

  React.useEffect(() => {
    document.title = "Genezio | Logs";
  }, []);

  React.useEffect(() => {
    fetchCurrentCollaboratorDetails();
  }, []);

  React.useEffect(() => {
    const runAsync = async () => {
      const projectRes: any = await getProjectById(projectId ?? "");
      const localActiveEnv = projectRes.data.project.projectEnvs.find((env: any) => env.id === envId);

      const res = await getClassById(classId ?? "");

      if (res?.data?.status === "ok") {
        setClassInfo(res.data.class);
        return;
      } else {
        console.log(res?.data?.error?.message);
      }

      const activeFunction = localActiveEnv.functions.find((functionInfo: any) => functionInfo.id === classId);
      setClassInfo(activeFunction);
      setIsFunction(true);
    };

    runAsync();
  }, []);

  React.useEffect(() => {
    const runAsync = async () => {
      const res: any = await getProjectById(projectId ?? "");
      if (res.data && res.data.status === "ok") {
        const localActiveEnv = res.data.project.projectEnvs.find((env: any) => env.id === envId);
        setActiveEnv(localActiveEnv);
        setProject(res.data.project);
      } else if (res.response.data.error.code === 2 || res.response.data.error.code === 6) {
        navigate("/dashboard");
      }
    };
    runAsync();
  }, []);

  React.useEffect(() => {
    setIsLoading(true);
    setLogs([]);

    let { localStartTime, localEndTime } = getTimeFromPeriod();
    if (localStartTime === 0 && localEndTime === 0) {
      localStartTime = startTime;
      localEndTime = endTime;
    }

    setStartTime(localStartTime);
    setEndTime(localEndTime);

    const runAsync = async () => {
      const response: any = isFunction
        ? await getFunctionLogs(classId ?? "", localStartTime, localEndTime, searchTerm)
        : await getLogs(classId ?? "", localStartTime, localEndTime, searchTerm);

      if (response?.data?.status === "ok") {
        setLogs(
          response?.data?.logs?.Events?.map((el: any) => {
            return {
              ...el,
              Message: el.Message.replace(/.*?(?=END|ERROR|REPORT|INFO|DEBUG|WARNING)/, ""),
            };
          }),
        );
        setNextToken(response.data.logs.NextToken);
        setLogsError("");
        setIsLoading(false);
      } else {
        setLogs([]);
        setNextToken(null);
        setIsLoading(false);
        setLogsError(response);
      }
    };

    runAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerFilter, logPeriod, isFunction]);

  const loadNextLogs = async () => {
    setIsLoading(true);
    const response: any = isFunction
      ? await getFunctionLogs(classId ?? "", startTime, endTime, searchTerm, nextToken)
      : await getLogs(classId ?? "", startTime, endTime, searchTerm, nextToken);

    setLogs(
      logs.concat(
        response.data.logs.Events.map((el: any) => {
          return {
            ...el,
            Message: el.Message.replace(/.*?(?=END|ERROR|REPORT|INFO|DEBUG|WARNING)/, ""),
          };
        }),
      ),
    );
    if (response.data.logs.Events.length === 0) {
      setDisableLoadMore(true);
    }
    setNextToken(response.data.logs.NextToken);
    setIsLoading(false);
  };

  const handleSearchTermChange = (e: any) => {
    e.preventDefault();
    setTriggerFilter(!triggerFilter);
    setDisableLoadMore(false);
  };

  const handlerRefresh = () => {
    setTriggerFilter(!triggerFilter);
    setDisableLoadMore(false);
  };

  const infiniteLoad = (e: any) => {
    const bottom = Math.abs(e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight) < 1;
    if (bottom && !disableLoadMore) {
      loadNextLogs();
    }
  };

  const fetchCurrentCollaboratorDetails = async () => {
    const res: any = await getCurrentCollaboratorDetails(projectId || "");

    if (res.status === 200 && res.data.status === "ok") {
      setCurrentCollaboratorDetails(res.data);
    }
  };

  const handleWebhooksDisplay = () => {
    if (!classInfo || !classInfo.ast || !classInfo.ast.methods) {
      return [];
    }
    const webhooks: string[] = [];

    // loop methods from the current class
    for (var i = 0; i < classInfo.ast.methods.length; i++) {
      const method = classInfo?.ast?.methods[i];
      if (method.type !== "http") continue;

      const trimmedCloudUrl = (classInfo?.cloudUrl || "").trim().replace(/\/+$/, "");
      const webhookUrlTemplate = `${trimmedCloudUrl}/${classInfo?.name}/${method?.name}`;
      webhooks.push(webhookUrlTemplate);
    }
    return webhooks;
  };

  const webhooks = handleWebhooksDisplay();

  return (
    <div onScroll={(e: any) => infiniteLoad(e)} style={{ overflowY: "auto", height: "88vh", overflowX: "hidden" }}>
      {/* <!-- row  --> */}
      <Row className="mt-3">
        <Col sm={12}>
          <Card>
            <Card.Header>
              <h4 className="card-title">Logs</h4>
            </Card.Header>
            <Card.Body className="pt-0">
              <Row className="mb-2 mt-2 pt-2 pb-2 border">
                <Col sm={8} className="col-8">
                  <div className="d-flex pt-1">
                    <div className="me-3">
                      <h6 className="mb-0" style={{ color: "#6F42C1" }}>
                        Name:
                      </h6>
                    </div>
                    <div>
                      {classInfo.name ? (
                        <p className="mb-0" style={{ color: "#6F42C1" }}>
                          {classInfo.name}
                        </p>
                      ) : (
                        <Spinner variant="primary" animation="border" size="sm" />
                      )}
                    </div>
                  </div>
                </Col>
                <Col sm={4} className="col-4">
                  <div className="d-flex float-end pe-4">
                    <div className="me-3">
                      <Button
                        size="sm"
                        variant="success"
                        style={{
                          border: "1px solid #6F42C1",
                          backgroundColor: darkMode ? "transparent" : "#F6F3FA",
                          color: "#6F42C1",
                        }}
                        onClick={() => testProject()}
                        disabled={
                          activeEnv.name === "prod" && currentCollaboratorDetails.role === "collaborator" ? true : false
                        }
                      >
                        Test Project
                      </Button>
                    </div>
                    {/* <div>
                      <DropdownButton
                        size="sm"
                        id="dropdown-basic-button"
                        title="Actions"
                        variant="light"
                        autoClose={true}
                      >
                        <Dropdown.Item
                          as="button"
                          onClick={() => handleChangeStatus("active")}
                          disabled={
                            isChangingStatus ||
                            classInfo.status === "active" ||
                            (activeEnv.name === "prod" && currentCollaboratorDetails.role === "collaborator")
                              ? true
                              : false
                          }
                        >
                          Activate
                        </Dropdown.Item>
                        <Dropdown.Item
                          as="button"
                          onClick={() => handleChangeStatus("paused")}
                          disabled={
                            isChangingStatus ||
                            classInfo.status === "paused" ||
                            (activeEnv.name === "prod" && currentCollaboratorDetails.role === "collaborator")
                              ? true
                              : false
                          }
                        >
                          Pause
                        </Dropdown.Item>
                      </DropdownButton>
                    </div> */}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>{error ? <div className="text-danger mb-3">{error}</div> : null}</Col>
                <Col sm={12}>
                  <div className="mb-2">
                    <b className="me-1">Status:</b>{" "}
                    {isChangingStatus ? <Spinner variant="primary" animation="border" size="sm" /> : classInfo.status}
                  </div>
                </Col>
              </Row>

              {webhooks?.length ? (
                <Row className="d-flex">
                  <b className="my-0">Webhooks:</b>
                  <Col className="p-0">
                    {webhooks?.map((webhook: any) => (
                      <div key={webhook} className="d-flex" style={{ minHeight: "25px" }}>
                        <p className="mb-1">{webhook}</p>

                        {buttonCopyState === webhook ? (
                          <i className="fa fa-check text-success m-1" aria-hidden="true" />
                        ) : (
                          <img
                            onClick={() => {
                              copyToClipboard(webhook, webhook, buttonCopyState, setButtonCopyState);
                            }}
                            src={require("./../assets/img/svgs/copy_icon.svg").default}
                            alt="Copy"
                            className="mx-1"
                            style={{ width: "25px", cursor: "pointer" }}
                          />
                        )}
                      </div>
                    ))}
                  </Col>
                </Row>
              ) : (
                ""
              )}
            </Card.Body>
            <Card.Header>
              <h4 className="card-title">All Logs</h4>
              <p className="mb-2">
                The filter bar below helps you find and match terms, phrases or values in the log events.
              </p>
            </Card.Header>
            <Card.Body className="pt-0 example1-table">
              <Row className="py-1">
                <Col md={4}>
                  <Form onSubmit={(e) => handleSearchTermChange(e)}>
                    <InputGroup className="mb-3">
                      <Form.Control
                        placeholder="Filter logs"
                        aria-label="Filter logs"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </InputGroup>

                    <Button
                      variant="outline-primary"
                      style={{ display: "none" }}
                      type="submit"
                      onClick={(e) => {
                        handleSearchTermChange(e);
                      }}
                    >
                      Apply
                    </Button>
                  </Form>
                </Col>
                <Col md={2} style={{ display: "flex" }}>
                  <InputGroup className="mb-3" style={{ display: "flex", alignItems: "center" }}>
                    {searchTerm.length > 0 ? " Press enter to search" : ""}
                  </InputGroup>
                </Col>
                <Col
                  md={6}
                  style={{
                    textAlign: "right",
                  }}
                >
                  <ButtonGroup aria-label="Basic example">
                    <Button
                      variant="light"
                      onClick={() => {
                        handlerRefresh();
                      }}
                    >
                      <i className="fa fa-refresh" aria-hidden="true"></i>
                    </Button>
                    <Button
                      variant="light"
                      className={logPeriod === "1m" ? "active" : ""}
                      onClick={() => {
                        setDisableLoadMore(false);
                        setLogPeriod("1m");
                      }}
                    >
                      1m
                    </Button>
                    <Button
                      variant="light"
                      className={logPeriod === "5m" ? "active" : ""}
                      onClick={() => {
                        setDisableLoadMore(false);
                        setLogPeriod("5m");
                      }}
                    >
                      5m
                    </Button>
                    <Button
                      variant="light"
                      className={logPeriod === "10m" ? "active" : ""}
                      onClick={() => {
                        setDisableLoadMore(false);
                        setLogPeriod("10m");
                      }}
                    >
                      10m
                    </Button>
                    <Button
                      variant="light"
                      className={logPeriod === "30m" ? "active" : ""}
                      onClick={() => {
                        setDisableLoadMore(false);
                        setLogPeriod("30m");
                      }}
                    >
                      30m
                    </Button>
                    <Button
                      variant="light"
                      className={logPeriod === "1h" ? "active" : ""}
                      onClick={() => {
                        setDisableLoadMore(false);
                        setLogPeriod("1h");
                      }}
                    >
                      1h
                    </Button>
                    <Button
                      variant="light"
                      className={logPeriod === "12h" ? "active" : ""}
                      onClick={() => {
                        setDisableLoadMore(false);
                        setLogPeriod("12h");
                      }}
                    >
                      12h
                    </Button>
                    <Button
                      variant="light"
                      className={logPeriod === "24h" ? "active" : ""}
                      onClick={() => {
                        setDisableLoadMore(false);
                        setLogPeriod("24h");
                      }}
                    >
                      24h
                    </Button>
                    <Button
                      variant="light"
                      className={logPeriod === "48h" ? "active" : ""}
                      onClick={() => {
                        setDisableLoadMore(false);
                        setLogPeriod("48h");
                      }}
                    >
                      48h
                    </Button>
                    <Button
                      variant="light"
                      className={logPeriod === "72h" ? "active" : ""}
                      onClick={() => {
                        setDisableLoadMore(false);
                        setLogPeriod("72h");
                      }}
                    >
                      72h
                    </Button>

                    <Dropdown className=" main-profile-menu nav nav-item nav-link ps-lg-2">
                      <Dropdown.Toggle className="new nav-link profile-user d-flex p-0 m-0" variant="">
                        <Button variant="light" className={logPeriod.includes("custom") ? "active" : ""}>
                          Custom
                        </Button>
                      </Dropdown.Toggle>
                      <Dropdown.Menu style={{ width: "50em", padding: "3em" }}>
                        <Row>
                          <Col md={6}>
                            <div className="main-content-label mg-b-5">Start Date</div>
                            <Row className="row-sm">
                              <InputGroup className="input-group col-xl-12 datetimepicker-2">
                                <InputGroup.Text className="input-group-text ">
                                  <i className="typcn typcn-calendar-outline tx-24 lh--9 op-6"></i>
                                </InputGroup.Text>
                                <Form className="form-control" noValidate>
                                  <TextField
                                    id="datetime-local"
                                    type="datetime-local"
                                    value={moment(startTime).format("YYYY-MM-DDTHH:mm")}
                                    onChange={(e) => {
                                      setStartTime(new Date(e.target.value).getTime());
                                      setLogPeriod("custom-" + Math.random().toString());
                                      setDisableLoadMore(false);
                                    }}
                                  />
                                </Form>
                              </InputGroup>
                            </Row>
                          </Col>
                          <Col md={6}>
                            <div className="main-content-label mg-b-5">End Date</div>
                            <Row className="row-sm">
                              <InputGroup className="input-group col-xl-12 datetimepicker-2">
                                <InputGroup.Text className="input-group-text ">
                                  <i className="typcn typcn-calendar-outline tx-24 lh--9 op-6"></i>
                                </InputGroup.Text>
                                <Form className="form-control" noValidate>
                                  <TextField
                                    id="datetime-local"
                                    type="datetime-local"
                                    value={moment(endTime).format("YYYY-MM-DDTHH:mm")}
                                    onChange={(e) => {
                                      setEndTime(new Date(e.target.value).getTime());
                                      setLogPeriod("custom-" + Math.random().toString());
                                      setDisableLoadMore(false);
                                    }}
                                  />
                                </Form>
                              </InputGroup>
                            </Row>
                          </Col>
                        </Row>
                      </Dropdown.Menu>
                    </Dropdown>
                  </ButtonGroup>
                </Col>
              </Row>

              {logsError?.response?.data.status === "error" && (project.classes || project.functions) && (
                <Alert variant="danger">{logsError?.response?.data.error.message}</Alert>
              )}

              <div className="table-responsive" style={{ overflow: "initial" }}>
                <table className="table table-bordered mb-0">
                  <thead>
                    <tr>
                      <th className="text-left">
                        <span className="tabletitle">Timestamp</span>
                      </th>
                      <th className="text-left">
                        <span className="tabletitle">Message</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {logs.map((elem: any, key: GLint64) => {
                      return (
                        <tr key={key}>
                          <td className="text-left text-nowrap">{moment.unix(elem.Timestamp / 1000).format("")}</td>
                          <td
                            className="text-left"
                            style={{
                              width: "100%",
                              color:
                                elem.Message.search("ERROR") !== -1
                                  ? "#f9301e"
                                  : elem.Message.search("WARNING") !== -1
                                    ? "#f9b31e"
                                    : elem.Message.search("INFO") !== -1
                                      ? "#28944F"
                                      : "inherit",
                            }}
                          >
                            {/* show {elem.Message} and make INFO, ERROR with bold  - do the split by /t and space*/}
                            {/* regex that mathc by \t and space */}
                            {/* split by regex */}
                            <div
                              style={{
                                wordBreak: "break-word",
                                whiteSpace: "pre-wrap",
                              }}
                            >
                              {elem.Message.split(/(\t|\s|:)/).map((word: any) => {
                                if (MESSAGES_TYPES.includes(word)) {
                                  return <b key={Math.random() * 1000}>{word}</b>;
                                }
                                return word;
                              })}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {!isLoading && logs.length === 0 ? (
                  <>
                    <Row>
                      <Col lg={12} className="my-3 text-center">
                        There are no logs for the specified filters and period
                      </Col>
                    </Row>
                  </>
                ) : null}

                {isLoading ? <GenezioSpinner /> : null}

                {!isLoading && nextToken !== "" && logs.length !== 0 ? (
                  <>
                    {/* Load more button */}
                    <Row>
                      <Col sm={12} className="col-12 my-3 text-center">
                        <Button disabled={disableLoadMore} variant="primary" onClick={() => loadNextLogs()}>
                          Load More
                        </Button>
                      </Col>
                    </Row>
                  </>
                ) : null}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Lambda;
