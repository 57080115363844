import React, { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

const Auth = () => {
  const [loading, setLoading] = useState(true);
  const urlParams = new URLSearchParams(window.location.search);
  const redirect: string = urlParams.get("redirect")?.toString() || "";

  let navigate = useNavigate();

  React.useEffect(() => {
    if (localStorage?.getItem("apiToken")) {
      if (redirect === "express-getting-started") {
        navigate("/express-getting-started", { replace: true });
        return;
      }
      if (redirect === "new-project") {
        navigate("/new-project", { replace: true });
        return;
      }
      if (redirect === "html-getting-started") {
        navigate("/html-getting-started", { replace: true });
        return;
      }
      if (redirect === "angular-getting-started") {
        navigate("/angular-getting-started", { replace: true });
        return;
      }
      if (redirect === "react-getting-started") {
        navigate("/react-getting-started", { replace: true });
        return;
      }
      if (redirect === "faas-getting-started") {
        navigate("/faas-getting-started", { replace: true });
        return;
      }
      if (redirect === "vue-getting-started") {
        navigate("/vue-getting-started", { replace: true });
        return;
      }
      if (redirect === "fastify-getting-started") {
        navigate("/fastify-getting-started", { replace: true });
        return;
      }
      navigate("/dashboard", { replace: true });
      return;
    }

    setLoading(false);
  }, []);

  if (loading) {
    return null;
  }

  return (
    <div>
      <Outlet />
    </div>
  );
};

export default Auth;
