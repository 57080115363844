import React from "react";
//import GettingStartedCode from "../components/GettingStartedCode";

export default function FaasGettingStarted() {
  window.location.replace("/fork?repository=https://github.com/Genez-io/faas-getting-started");

  return (
    <React.Fragment>
      {/* <GettingStartedCode
        projectName="function-getting-started"
        repository="https://github.com/Genez-io/faas-getting-started.git"
        title="Serverless Function Getting Started"
        description="This is a simple serverless function that returns a hello world message."
      /> */}
    </React.Fragment>
  );
}
