import { toast, ToastOptions } from "react-toastify";
import { CodeSnippetsOptions, CodeSnippets } from "../../models/IntegrationModels";

export const SENSITIVE_STRING = "**********";

const NEON_NODE_PREREQUISITES = `npm install pg @types/pg @genezio/types`;
const NEON_NODE_SNIPPET = `// Import the postgres driver library at the top of your file
import pg from 'pg'
const { Pool } = pg

// Use the library in your code
const pool = new Pool({
  connectionString: process.env.NEON_POSTGRES_URL,
  ssl: true,
});

await pool.query(
  "CREATE TABLE IF NOT EXISTS users (id SERIAL PRIMARY KEY, name VARCHAR(255))"
);

const name = "John Doe";
await pool.query("INSERT INTO users (name) VALUES ($1)", [name]);
const result = await pool.query("SELECT * FROM users");

console.log(JSON.stringify(result.rows));
`;
const NEON_ENV_SNIPPET = `NEON_POSTGRES_URL="postgres://<username>:<password>@<hostname>:<port>/<database>"`;
const NEON_CLI_SNIPPET = `psql "postgres://<username>:<password>@<hostname>:<port>/<database>"`;

const REDIS_NODE_PREREQUISITES = `npm install ioredis @genezio/types`;
const REDIS_NODE_SNIPPET = `import Redis from "ioredis"

client = new Redis(process.env.UPSTASH_REDIS_URL);

await this.client.set('user', name);
const result = await this.client.get('user');
return JSON.stringify(result);
`;
const UPSTASH_REDIS_PREREQUISITES = `npm install @upstash/redis @genezio/types`;
const UPSTASH_REDIS_SNIPPET = `import { Redis } from '@upstash/redis'

client = new Redis({
  url: process.env.UPSTASH_REDIS_REST_URL,
  token: process.env.UPSTASH_REDIS_REST_TOKEN,
})

await this.client.set('user', name);
const result = await this.client.get('user');
return JSON.stringify(result);
`;
const REDIS_ENV_SNIPPET = `UPSTASH_REDIS_URL="redis://<username>:<password>@<hostname>:<port>"
UPSTASH_REDIS_REST_URL="<url>"
UPSTASH_REDIS_REST_TOKEN="<password>"`;
const REDIS_CLI_SNIPPET = `redis-cli -u redis://<username>:<password>@<hostname>:<port>`;

const EMAIL_SERVICE_CODE_SNIPPET = `import { MailService } from "@genezio/email-service";

const response = await MailService.sendMail({
  emailServiceToken: process.env.EMAIL_SERVICE_TOKEN,
  from: email,
  to: email,
  subject: subject,
  text: message
});

if (!response.success) {
  return response.errorMessage;
}

return "success";
`;

const QSTASH_CODE_SNIPPET = `import { GenezioDeploy, GenezioMethod,
  GenezioHttpRequest, GenezioHttpResponse } from "@genezio/types";
import axios from 'axios';


const url = \`https://qstash.upstash.io/v2/publish/\${process.env.QUEUE_WEBHOOK_URL}\`;
const payload = {
  "ping": "pong",
};

const headers = {
  'Authorization': \`Bearer \${process.env.QSTASH_TOKEN}\`,
  'Content-Type': 'application/json',
};

await axios
  .post(url, payload, { headers: headers })
  .then((response) => {
    console.log(response.data);
    return true
  })
  .catch((error) => {
    console.error(error);
    return false
  });
return true

const ping: string = request.body.ping;
console.log(ping);

const response: GenezioHttpResponse = {
  body: {},
  headers: { 'content-type': 'application/json' },
  statusCode: '200',
};
return response;
`;

const QSTASH_ENV_SNIPPET =
  'QSTASH_URL="https://qstash.upstash.io/v2/publish/"' +
  "\n" +
  "QSTASH_TOKEN=<token>" +
  "\n" +
  "QSTASH_CURRENT_SIGNING_KEY=<current-signing-key>" +
  "\n" +
  "QSTASH_NEXT_SIGNING_KEY=<next-siging-key>" +
  "\n\n" +
  "QUEUE_WEBHOOK_URL=<todo-add-webhook-url>/QstashService/ping";

export const codeSnippetsOptions: { [key: string]: CodeSnippetsOptions } = {
  neon: ["nodejs", ".env", "psql"],
  upstashRedis: ["nodejs", "@upstash/redis", ".env", "redis-cli"],
  upstash: ["nodejs", "@upstash/redis", ".env", "redis-cli"],
  emailService: ["nodejs", ".env"],
  upstashQstash: ["nodejs", ".env"],
  neonGenezioIntegration: ["nodejs"],
};

export const getCodeSnippetsOptions = (integrationName: string) => {
  return codeSnippetsOptions[integrationName];
};

export const hashUpstashPassword = (inputString: string) => {
  const regex = /:\/\/([^@]+)@/; // Regular expression to match the password
  const redactedString = inputString.replace(regex, `://${SENSITIVE_STRING}@`);
  return redactedString;
};

export const hashNeonPassword = (inputString: string) => {
  const regex = /postgres:\/\/(.*):(.*)@/; // Regular expression to match the password
  const redactedString = inputString.replace(regex, `postgres://$1:${SENSITIVE_STRING}@`);
  return redactedString;
};
export const getNeonGenezioIntegrationCodeSnippets = (integrationEnv: any): { [key: string]: CodeSnippets } => {
  return {
    nodejs: [
      {
        title: "Save your databse connection URL",
        codeSnippet: "postgresql://****************************************",
        copySnippet: `${integrationEnv[0].value}`,
        language: "bash",
        revealCredentialButton: true,
      },
      {
        title: "Install pg",
        codeSnippet: NEON_NODE_PREREQUISITES,
        copySnippet: NEON_NODE_PREREQUISITES,
        language: "npm",
      },
      {
        title: "Connect to your database",
        codeSnippet: NEON_NODE_SNIPPET.replace("process.env.NEON_POSTGRES_URL", `${integrationEnv[1].value}`),
        copySnippet: NEON_NODE_SNIPPET.replace("process.env.NEON_POSTGRES_URL", `${integrationEnv[1].value}`),
        language: "javascript",
      },
    ],
  };
};

export const getNeonCodeSnippets = (
  isIntegrationActive: boolean,
  integrationEnv: any,
): { [key: string]: CodeSnippets } => {
  return {
    psql: [
      {
        title: "Connect to your database",
        codeSnippet: `${
          isIntegrationActive
            ? NEON_CLI_SNIPPET.replace(
                "postgres://<username>:<password>@<hostname>:<port>/<database>",
                hashNeonPassword(integrationEnv[0].value),
              )
            : NEON_CLI_SNIPPET
        }`,
        copySnippet: isIntegrationActive
          ? NEON_CLI_SNIPPET.replace(
              "postgres://<username>:<password>@<hostname>:<port>/<database>",
              integrationEnv[0].value,
            )
          : NEON_CLI_SNIPPET,
        language: "bash",
        revealCredentialButton: true,
      },
    ],
    nodejs: [
      {
        title: "Install pg",
        codeSnippet: NEON_NODE_PREREQUISITES,
        copySnippet: NEON_NODE_PREREQUISITES,
        language: "npm",
      },
      {
        title: "For testing locally, copy the .env file",
        codeSnippet: `${
          isIntegrationActive
            ? NEON_ENV_SNIPPET.replace(
                "postgres://<username>:<password>@<hostname>:<port>/<database>",
                hashNeonPassword(integrationEnv[0].value),
              )
            : NEON_ENV_SNIPPET
        }`,
        copySnippet: isIntegrationActive
          ? NEON_ENV_SNIPPET.replace(
              "postgres://<username>:<password>@<hostname>:<port>/<database>",
              integrationEnv[0].value,
            )
          : NEON_ENV_SNIPPET,
        language: "env",
        revealCredentialButton: true,
      },
      {
        title: "Connect to your database",
        codeSnippet: NEON_NODE_SNIPPET,
        copySnippet: NEON_NODE_SNIPPET,
        language: "javascript",
      },
    ],
    ".env": [
      {
        title: "Connect to your database",
        codeSnippet: `${
          isIntegrationActive
            ? NEON_ENV_SNIPPET.replace(
                "postgres://<username>:<password>@<hostname>:<port>/<database>",
                hashNeonPassword(integrationEnv[0].value),
              )
            : NEON_ENV_SNIPPET
        }`,
        copySnippet: isIntegrationActive
          ? NEON_ENV_SNIPPET.replace(
              "postgres://<username>:<password>@<hostname>:<port>/<database>",
              integrationEnv[0].value,
            )
          : NEON_ENV_SNIPPET,
        language: "env",
        revealCredentialButton: true,
      },
    ],
  };
};

export const getUpstashCodeSnippets = (
  isIntegrationActive: boolean,
  integrationEnv: any,
): { [key: string]: CodeSnippets } => {
  return {
    "redis-cli": [
      {
        title: "Connect to your database",
        codeSnippet: `${
          isIntegrationActive
            ? REDIS_CLI_SNIPPET.replace(
                "redis://<username>:<password>@<hostname>:<port>",
                hashUpstashPassword(integrationEnv[0].value),
              )
            : REDIS_CLI_SNIPPET
        }`,
        copySnippet: isIntegrationActive
          ? REDIS_CLI_SNIPPET.replace("redis://<username>:<password>@<hostname>:<port>", integrationEnv[0].value)
          : REDIS_CLI_SNIPPET,
        language: "bash",
        revealCredentialButton: true,
      },
    ],
    "@upstash/redis": [
      {
        title: "Install @upstash/redis",
        warning: (
          <p className="mb-0">
            Node version &gt;= 18.0.0 required. Check to{" "}
            <a
              className="text-decoration-underline text-muted"
              target="blank"
              href={
                process.env.REACT_APP_GENEZIO_DOCUMENTATION + "project-structure/genezio-configuration-file#options"
              }
            >
              {" "}
              documentation
            </a>{" "}
            to set the node runtime version in your project
          </p>
        ),
        codeSnippet: UPSTASH_REDIS_PREREQUISITES,
        copySnippet: UPSTASH_REDIS_PREREQUISITES,
        language: "npm",
      },
      {
        title: "For testing locally, copy the .env file",
        codeSnippet: `${
          isIntegrationActive
            ? REDIS_ENV_SNIPPET.replace(
                "redis://<username>:<password>@<hostname>:<port>",
                hashUpstashPassword(integrationEnv[0].value),
              )
                .replace("<url>", integrationEnv[1].value)
                .replace("<password>", SENSITIVE_STRING)
            : REDIS_ENV_SNIPPET
        }`,
        copySnippet: isIntegrationActive
          ? REDIS_ENV_SNIPPET.replace("redis://<username>:<password>@<hostname>:<port>", integrationEnv[0].value)
              .replace("<url>", integrationEnv[1].value)
              .replace("<password>", integrationEnv[2].value)
          : REDIS_ENV_SNIPPET,
        language: "env",
        revealCredentialButton: true,
      },
      {
        title: "Connect to your database.",
        codeSnippet: UPSTASH_REDIS_SNIPPET,
        copySnippet: UPSTASH_REDIS_SNIPPET,
        language: "javascript",
      },
    ],
    ".env": [
      {
        title: "Connect to your database",
        codeSnippet: `${
          isIntegrationActive
            ? REDIS_ENV_SNIPPET.replace(
                "redis://<username>:<password>@<hostname>:<port>",
                hashUpstashPassword(integrationEnv[0].value),
              )
                .replace("<url>", integrationEnv[1].value)
                .replace("<password>", SENSITIVE_STRING)
            : REDIS_ENV_SNIPPET
        }`,
        copySnippet: isIntegrationActive
          ? REDIS_ENV_SNIPPET.replace("redis://<username>:<password>@<hostname>:<port>", integrationEnv[0].value)
              .replace("<url>", integrationEnv[1].value)
              .replace("<password>", integrationEnv[2].value)
          : REDIS_ENV_SNIPPET,
        language: "env",
        revealCredentialButton: true,
      },
    ],
    nodejs: [
      {
        title: "Install ioredis",
        codeSnippet: REDIS_NODE_PREREQUISITES,
        copySnippet: REDIS_NODE_PREREQUISITES,
        language: "npm",
      },
      {
        title: "For testing locally, copy the .env file",
        codeSnippet: `${
          isIntegrationActive
            ? REDIS_ENV_SNIPPET.replace(
                "redis://<username>:<password>@<hostname>:<port>",
                hashUpstashPassword(integrationEnv[0].value),
              )
                .replace("<url>", integrationEnv[1].value)
                .replace("<password>", SENSITIVE_STRING)
            : REDIS_ENV_SNIPPET
        }`,
        copySnippet: isIntegrationActive
          ? REDIS_ENV_SNIPPET.replace("redis://<username>:<password>@<hostname>:<port>", integrationEnv[0].value)
              .replace("<url>", integrationEnv[1].value)
              .replace("<password>", integrationEnv[2].value)
          : REDIS_ENV_SNIPPET,
        language: "env",
        revealCredentialButton: true,
      },
      {
        title: "Connect to your database",
        codeSnippet: REDIS_NODE_SNIPPET,
        copySnippet: REDIS_NODE_SNIPPET,
        language: "javascript",
      },
    ],
  };
};

export const getEmailServiceCodeSnippets = (
  isIntegrationActive: boolean,
  integrationEnv: any,
): { [key: string]: CodeSnippets } => {
  return {
    nodejs: [
      {
        title: "Install package @genezio/email-service",
        codeSnippet: "npm install @genezio/email-service",
        copySnippet: "npm install @genezio/email-service",
        language: "env",
      },
      {
        title: "For testing locally, copy the .env file",
        codeSnippet: `EMAIL_SERVICE_TOKEN="***********************"`,
        copySnippet: isIntegrationActive
          ? `EMAIL_SERVICE_TOKEN="${integrationEnv[0].value}"`
          : `EMAIL_SERVICE_TOKEN="<token>"`,
        language: "env",
        revealCredentialButton: true,
      },
      {
        title: "Connect to your email service",
        codeSnippet: EMAIL_SERVICE_CODE_SNIPPET,
        copySnippet: EMAIL_SERVICE_CODE_SNIPPET,
        language: "javascript",
      },
    ],
    ".env": [
      {
        title: "Add .env file",
        codeSnippet: `EMAIL_SERVICE_TOKEN="***********************"`,
        copySnippet: isIntegrationActive
          ? `EMAIL_SERVICE_TOKEN="${integrationEnv[0].value}"`
          : `EMAIL_SERVICE_TOKEN="<token>"`,
        language: "env",
        revealCredentialButton: true,
      },
    ],
  };
};

export const getUpstashQstashCodeSnippets = (
  isIntegrationActive: boolean,
  integrationEnv: any,
): { [key: string]: CodeSnippets } => {
  return {
    nodejs: [
      {
        title: "Install required packages",
        codeSnippet: "npm install @genezio/types axios",
        copySnippet: "npm install @genezio/types axios",
        language: "env",
      },
      {
        title: "For testing locally, copy the .env file",
        codeSnippet: QSTASH_ENV_SNIPPET,
        copySnippet: isIntegrationActive
          ? QSTASH_ENV_SNIPPET.replace("<token>", integrationEnv[1].value)
              .replace("<current-signing-key>", integrationEnv[2].value)
              .replace("<next-siging-key>", integrationEnv[3].value)
          : QSTASH_ENV_SNIPPET,
        language: "env",
        revealCredentialButton: true,
      },
      {
        title: "Add your class code",
        codeSnippet: QSTASH_CODE_SNIPPET,
        copySnippet: QSTASH_CODE_SNIPPET,
        language: "typescript",
      },
    ],
    ".env": [
      {
        title: "Add .env file",
        codeSnippet: QSTASH_ENV_SNIPPET,
        copySnippet: isIntegrationActive
          ? QSTASH_ENV_SNIPPET.replace("<token>", integrationEnv[1].value)
              .replace("<current-signing-key>", integrationEnv[2].value)
              .replace("<next-siging-key>", integrationEnv[3].value)
          : QSTASH_ENV_SNIPPET,
        language: "env",
        revealCredentialButton: true,
      },
    ],
  };
};

export const getCodeSnippets = (integrationName: string, activeOption: string, integrationEnv: any) => {
  const isIntegrationActive = integrationEnv !== null && integrationEnv.length > 0 ? true : false;

  switch (integrationName) {
    case "neon":
      return getNeonCodeSnippets(isIntegrationActive, integrationEnv)[activeOption];
    case "upstash-redis":
      return getUpstashCodeSnippets(isIntegrationActive, integrationEnv)[activeOption];
    case "email-service":
      return getEmailServiceCodeSnippets(isIntegrationActive, integrationEnv)[activeOption];
    case "upstash-qstash":
      return getUpstashQstashCodeSnippets(isIntegrationActive, integrationEnv)[activeOption];
    case "neonGenezioIntegration":
      return getNeonGenezioIntegrationCodeSnippets(integrationEnv)[activeOption];
    default:
      return [];
  }
};

export const regions = [
  { label: "aws-us-east-1", value: "aws-us-east-1" },
  { label: "aws-us-east-2", value: "aws-us-east-2" },
  { label: "aws-us-west-2", value: "aws-us-west-2" },
  { label: "aws-eu-central-1", value: "aws-eu-central-1" },
  { label: "aws-ap-southeast-1", value: "aws-ap-southeast-1" },
  { label: "aws-il-central-1", value: "aws-il-central-1" },
];

export const postgresVersion = [
  {
    label: "14",
    value: 14,
  },
  {
    label: "15",
    value: 15,
  },
];

export const sendToast = (message: string, type: string) => {
  const toastOptions: ToastOptions = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    theme: "light",
  };

  if (type === "error") {
    toast.error(message, toastOptions);
  } else if (type === "success") {
    toast.success(message, toastOptions);
  }
};

const NEON_REDIRECT_URI = process.env.REACT_APP_NEON_REDIRECT_URI;
const UPSTASH_REDIRECT_URI = process.env.REACT_APP_UPSTASH_REDIRECT_URI;

export const NeonOauthConnectionUrl = `https://oauth2.neon.tech/oauth2/auth?client_id=genezio&redirect_uri=${NEON_REDIRECT_URI}&scope=offline%20offline_access%20urn%3Aneoncloud%3Aprojects%3Acreate%20urn%3Aneoncloud%3Aprojects%3Aread%20urn%3Aneoncloud%3Aprojects%3Aupdate%20urn%3Aneoncloud%3Aprojects%3Adelete&response_type=code&code_challenge=mRzr9QQAgSkCclVkPad6aZ7dD5vHQsi5r7_PUwprWUA&state=hy59Vx34Ue1dhAlz175HEXNcLDd3S01d&code_challenge_method=S256`;
export const UpstashOauthConnectionUrl = `https://auth.upstash.com/authorize?response_type=code&audience=upstash-api&scope=offline_access&client_id=eRZWzFtAltz5mTn2t0M4EAOnTn4oX8D2&redirect_uri=${UPSTASH_REDIRECT_URI}`;
