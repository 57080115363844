import { useState } from "react";
import { Row, Col, InputGroup, Form, Spinner, DropdownButton, Dropdown, Button } from "react-bootstrap";
import { handleLinkOpen } from "../../NewProject/utils";
import { useNavigate } from "react-router-dom";
import { ConnectToGithubProps } from "../../../models/NewProjectFlowModels";

const ConnectToGithubImportProject: React.FC<ConnectToGithubProps> = ({
  githubUserDetails,
  isConnectedToGithub,
  githubUserRepos,
  settingsUrl,
  connectMoreAccounts,
  githubReposLoading,
}) => {
  const [search, setSearch] = useState("");
  const isDarkMode = localStorage.getItem("darkMode") === "true";

  const navigate = useNavigate();
  return (
    <Col lg={6} className="m-auto p-0 border rounded " style={{ border: "1px solid red" }}>
      <div
        style={{
          padding: `${isConnectedToGithub ? "36px" : "70px"} 20px ${!isConnectedToGithub ? "50px" : "20px"} 20px`,
        }}
        className="m-auto d-flex flex-column align-items-center"
      >
        <h4 className="fw-bold text-center">Import your project directly from your GitHub account.</h4>

        <p className="mt-3 text-center">To deploy a new Project, import an existing Git Repository.</p>

        {!isConnectedToGithub ? (
          <>
            <button
              onClick={() =>
                handleLinkOpen(`https://github.com/apps/${process.env.REACT_APP_GITHUB_APP_NAME}/installations/new`)
              }
              className={`btn ${isDarkMode ? "github-new-project-btn-light" : "github-new-project-btn"}  mt-3`}
            >
              <i className="fab fa-github"></i>
              <span>Connect with Github</span>
            </button>
            <p className="text-center mt-4">
              If you prefer not to connect your GitHub,
              <br /> follow is the{" "}
              <a href="#" className="text-decoration-underline">
                step-by-step tutorial
              </a>{" "}
              to deploy your project.
            </p>
          </>
        ) : (
          <>
            <Row style={{ maxWidth: "382px" }} className="w-100 mt-3">
              <Col className="mx-1 border rounded d-flex align-items-center py-0 px-0">
                <DropdownButton
                  size="sm"
                  id="dropdown-basic-button"
                  style={{ width: "100%", maxWidth: "100%" }}
                  title={
                    <div className="d-flex align-items-center w-100" style={{ width: "100%" }}>
                      <i className="fab fa-github fs-4"></i>
                      <p className="mx-2 mb-0">{githubUserDetails?.name}</p>
                    </div>
                  }
                  variant="light"
                  autoClose={true}
                >
                  <Dropdown.Item
                    as="button"
                    onClick={() => window.open(`https://github.com/${githubUserDetails?.name}`, "_blank")}
                    className="d-flex align-items-center"
                  >
                    <div className="d-flex align-items-center w-100" style={{ width: "100%" }}>
                      <i className="fab fa-github fs-4"></i>
                      <p className="mx-2 mb-0">{githubUserDetails?.name}</p>
                    </div>
                  </Dropdown.Item>

                  <Dropdown.Item
                    as="button"
                    onClick={() => connectMoreAccounts()}
                    className="d-flex align-items-center"
                  >
                    <img src={require("../../../assets/img/svgs/add_github.svg").default} alt="add" />
                    <p className="mx-2 mb-0">Add GitHub Account</p>
                  </Dropdown.Item>
                  <Dropdown.Item
                    as="button"
                    onClick={() => window.open("https://github.com/settings/installations", "_blank")}
                    className="d-flex align-items-center"
                  >
                    <img src={require("../../../assets/img/svgs/remove_github.svg").default} alt="remove" />
                    <p className="mx-2 mb-0">Sign Out</p>
                  </Dropdown.Item>
                </DropdownButton>
              </Col>

              <Col style={{ position: "relative" }} className="d-flex align-items-center p-0 mx-1">
                <InputGroup>
                  {/* <i className="fa fa-search"></i> */}
                  <Form.Control
                    style={{ border: "none" }}
                    className="form-control w-100"
                    type="text"
                    onChange={(e) => setSearch(e.target.value)}
                    value={search}
                    placeholder="Search..."
                  ></Form.Control>
                </InputGroup>
              </Col>
            </Row>

            {githubReposLoading && (
              <Row className="my-4">
                <Spinner variant="primary" animation="border" />
              </Row>
            )}

            {githubUserRepos.length ? (
              <Row
                style={{ maxWidth: "382px", minHeight: "10em", maxHeight: "20em", overflowY: "scroll" }}
                className="w-100 mt-3 border rounded p-3 hide-scrollbars d-flex align-items-start"
              >
                {githubUserRepos
                  .filter((repo: any) => repo.name.toLowerCase().includes(search.toLowerCase()))
                  .map((repo: any, idx: any) => (
                    <Col
                      lg={12}
                      className={`d-flex justify-content-between align-items-center  p-0 ${
                        idx !== githubUserRepos.length - 1 && "mb-3"
                      }`}
                    >
                      <p className="m-0">{repo?.name}</p>

                      <Button
                        onClick={() => navigate(`/import/${repo.id}`)}
                        variant="outline-primary"
                        className={`btn btn-outline-primary py-1 ${repo?.imported ? "px-2" : "px-3"}`}
                        disabled={repo?.imported ? true : false}
                      >
                        {repo?.imported ? "Imported" : "Import"}
                      </Button>
                    </Col>
                  ))}
              </Row>
            ) : (
              ""
            )}

            <p className="mt-3 text-center">
              If your GitHub repository isn't visible here, please verify your{" "}
              <a href={settingsUrl} rel="noreferrer" target="_blank" className="text-decoration-underline">
                account permissions.
              </a>
            </p>
          </>
        )}
      </div>
    </Col>
  );
};

export default ConnectToGithubImportProject;
