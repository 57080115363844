import { useEffect, useState } from "react";
import { useTable, useSortBy, useGlobalFilter, usePagination } from "react-table";
import { Col, Card, Button, InputGroup, Form, Row, Tabs, Tab, Spinner } from "react-bootstrap";
import { PROJECT_TABLE_COLUMNS } from "../../configs/DashboardConfig";
import moment from "moment";
import { NavLink } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import { getProjectById } from "../../network/ApiAxios";

const GlobalFilter = ({ filter, setFilter }: any) => {
  return (
    <span className="d-flex ms-auto">
      <InputGroup>
        <InputGroup.Text>
          <i className="fa fa-search"></i>
        </InputGroup.Text>
        <Form.Control value={filter || ""} onChange={(e) => setFilter(e.target.value)} placeholder="Search..." />
      </InputGroup>
    </span>
  );
};

const Logs = () => {
  const { projectId, envId } = useParams<{ projectId?: string; envId: string }>();
  const navigate = useNavigate();
  const [project, setProject] = useState<any>({});
  const [classes, setClasses] = useState<any>([]);
  const [functions, setFunctions] = useState<any>([]);
  const [activeLogsTab, setActiveLogsTab] = useState<string>();

  const [activeEnv, setActiveEnv] = useState<any>({});
  const [isLoading, setIsLoading] = useState(true);

  const tableInstance = useTable(
    {
      columns: PROJECT_TABLE_COLUMNS,
      data: activeLogsTab === "functions" ? functions : classes,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    state,
    setGlobalFilter,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
  }: any = tableInstance;

  const { globalFilter, pageIndex, pageSize } = state;

  useEffect(() => {
    setPageSize(25);
  }, []);

  useEffect(() => {
    const runAsyncGetProjectInfo = async () => {
      const res: any = await getProjectById(projectId ?? "");
      if (res.data && res.data.status === "ok") {
        const localActiveEnv = res.data.project.projectEnvs.find((env: any) => env.id === envId);
        setActiveEnv(localActiveEnv);
        setProject(res.data.project);
        setClasses(localActiveEnv.classes);
        setFunctions(localActiveEnv.functions);
        setIsLoading(false);
        setActiveLogsTab(localActiveEnv.classes?.length ? "classes" : "functions");
      } else if (res.response.data.error.code === 2 || res.response.data.error.code === 6) {
        navigate("/dashboard");
      }
    };

    runAsyncGetProjectInfo();
  }, []);

  useEffect(() => {
    document.title = "Genezio | Logs";
  }, []);

  return (
    <Row className="mt-3">
      <Col sm={12} className="col-12">
        <Card>
          <Card.Header>
            <h4 className="card-title">Logs</h4>
          </Card.Header>
          <Card.Body className="pt-0 example1-table">
            <Tabs className="mb-4" id="logsTabs" activeKey={activeLogsTab} onSelect={(k: any) => setActiveLogsTab(k)}>
              {classes.length > 0 && (
                <Tab eventKey="classes" title="Classes" active={activeLogsTab === "classes" ? true : false} />
              )}
              {functions.length > 0 && (
                <Tab eventKey="functions" title="Functions" active={activeLogsTab === "functions" ? true : false} />
              )}
            </Tabs>
            <div className="table-responsive">
              <div className="d-flex align-items-center mb-3">
                <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
              </div>
              <table className="table text-nowrap mb-0">
                <thead>
                  <tr>
                    <th>
                      <span className="tabletitle">#</span>
                    </th>
                    <th>
                      <span className="tabletitle">{activeLogsTab === "functions" ? "Function" : "Class"} Name</span>
                    </th>
                    <th>
                      <span className="tabletitle">Last Edit Date</span>
                    </th>
                    <th>
                      <span className="tabletitle">Created Date</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {page.map((row: any, index: number) => {
                    const elems = row.original;
                    return (
                      <tr key={elems.name}>
                        <td>{index + 1}</td>
                        <td>
                          <NavLink style={{ color: "#0d6efd" }} to={`/project/${projectId}/${envId}/logs/${elems.id}`}>
                            {elems.name}
                          </NavLink>
                        </td>
                        <td>{moment.unix(elems.updatedAt).fromNow()}</td>
                        <td>{moment.unix(elems.createdAt).format("MMM D, YYYY [at] h:mm A")}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {isLoading ? (
                <Col lg={12} style={{ textAlign: "center" }} className="my-3">
                  <Spinner variant="primary" animation="border" />{" "}
                </Col>
              ) : null}
              <div className="d-block d-sm-flex mt-4 ">
                <span className="">
                  Page{" "}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length === 0 ? 1 : pageOptions.length}
                  </strong>{" "}
                </span>
                <span className="ms-sm-auto d-flex">
                  <Button
                    variant=""
                    className="btn-default tablebutton me-2 my-1"
                    onClick={() => {
                      previousPage();
                    }}
                    disabled={!canPreviousPage}
                  >
                    {" << "}
                  </Button>
                  <Button
                    variant=""
                    className="btn-default tablebutton me-2 my-1"
                    onClick={() => {
                      previousPage();
                    }}
                    disabled={!canPreviousPage}
                  >
                    {" < "}
                  </Button>
                  <Button
                    variant=""
                    className="btn-default tablebutton me-2 my-1"
                    onClick={() => {
                      nextPage();
                    }}
                    disabled={!canNextPage}
                  >
                    {" > "}
                  </Button>
                  <Button
                    variant=""
                    className="btn-default tablebutton me-2 my-1"
                    onClick={() => {
                      nextPage();
                    }}
                    disabled={!canNextPage}
                  >
                    {" >> "}
                  </Button>
                </span>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default Logs;
