import { useState, useEffect } from "react";
import { Modal, Form, Button, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { ActivateAuthModalProps, ActivateAuthReq, ActivateAuthResponse } from "../../../models/AuthModels";
import { activateAuth, getDatabaseConnectionURL } from "../../../network/ApiAxios";
import { sendToast } from "../../../views/Integrations/utils";

const customStyles = {
  placeholder: (provided: any) => ({
    ...provided,
    color: "white", // Set placeholder color to white
  }),
  input: (provided: any) => ({
    ...provided,
    color: "white",
  }),
  control: (provided: any) => ({
    ...provided,
    backgroundColor: "#090918",
    border: "none",
    color: "white",
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: "white",
  }),
  menu: (provided: any) => ({
    ...provided,
    backgroundColor: "#090918", // Set background color of the options parent
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#4a4a4a" : "#090918",
    color: "white", // Set text color
    ":hover": {
      backgroundColor: "#4a4a4a", // Change background color on hover
    },
  }),
};

const ActivateAuthModal: React.FC<ActivateAuthModalProps> = ({
  show,
  hide,
  projectId,
  envId,
  fetchAuth,
  databases,
  toggleDatabaseModal,
  selectedDbValue,
  setSelectedDbValue,
  disabledAuthFeature,
}) => {
  const darkMode = localStorage.getItem("darkMode") === "true";
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<ActivateAuthReq>({
    enabled: true,
    databaseType: "postgresql",
  });
  const [addYourOwnUri, setAddYourOwnUri] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleActivate = async () => {
    setLoading(true);
    const res: ActivateAuthResponse = await activateAuth(envId, data);
    if (res.status === 200) {
      hide();
      fetchAuth();
    } else {
      sendToast(res?.response?.data?.error?.message || "", "error");
    }

    setLoading(false);
  };

  const handleDbSelector = async (e: any) => {
    setSelectedDbValue(e);
    setData((prevData: any) => ({ ...prevData, databaseUrl: "" }));

    if (e.value === "Enter your own URI") {
      setAddYourOwnUri(true);
    } else if (e.value === "Create New Database") {
      toggleDatabaseModal();
    }
  };

  const fetchDbConnectionURL = async () => {
    const res: any = await getDatabaseConnectionURL(selectedDbValue?.value);
    if (res.status === 200 && res.data.status === "ok" && res.data.connectionUrl) {
      setData((prevData: any) => ({ ...prevData, databaseUrl: res.data.connectionUrl }));
    }
  };

  // Run for create new db option
  useEffect(() => {
    if (
      selectedDbValue &&
      selectedDbValue?.value !== "Enter your own URI" &&
      selectedDbValue?.value !== "Create New Database"
    ) {
      fetchDbConnectionURL();
    }
  }, [selectedDbValue]);

  return (
    <Modal show={show} className="smaller-modal-width" centered>
      <Modal.Body style={{ padding: "32px" }}>
        <div className="d-flex flex-column">
          <h5 className="" style={{ fontWeight: "600" }}>
            Authentication
          </h5>

          {disabledAuthFeature ? (
            <>
              <p className="text-muted mb-3">This feature is available only If you have genezio classes deployed.</p>
              <div className="d-flex justify-content-end mt-3">
                <Button
                  onClick={() => navigate(`/project/${projectId}/${envId}/`)}
                  className="btn border mx-2 px-2"
                  size="sm"
                  variant=""
                >
                  Cancel
                </Button>
                <a
                  target="_blank"
                  href="https://genezio.com/docs/cli-tool/cli-commands/genezio-addclass/"
                  rel="noreferrer"
                >
                  <Button size="sm" variant="primary" className="px-3">
                    Learn more
                  </Button>
                </a>
              </div>
            </>
          ) : (
            <>
              <p className="text-muted mb-3">
                To activate authentication in your application, fill in this form. For more info you can visit the{" "}
                <a
                  rel="noreferrer"
                  href={`${process.env.REACT_APP_GENEZIO_DOCUMENTATION}${"features/authentication"}`}
                  target="_blank"
                >
                  docs
                </a>
                .
              </p>

              <Form.Label className=" mb-2 mt-0">Database Type</Form.Label>
              <div key="inline-radio" className="mb-3">
                <Form.Check
                  inline
                  label="PostgreSQL"
                  name="database"
                  type="radio"
                  id="inline-database-2"
                  checked={data?.databaseType === "postgresql" ? true : false}
                  onChange={() => {
                    setData((prevData: any) => ({ ...prevData, databaseType: "postgresql", databaseUrl: "" }));
                    setSelectedDbValue(null);
                  }}
                />
                <Form.Check
                  inline
                  label="MongoDB"
                  name="database"
                  type="radio"
                  id="inline-database-1"
                  checked={data?.databaseType === "mongodb" ? true : false}
                  onChange={() => {
                    setData((prevData: any) => ({ ...prevData, databaseType: "mongodb", databaseUrl: "" }));
                    setSelectedDbValue(null);
                  }}
                />
              </div>

              {addYourOwnUri || data.databaseType === "mongodb" ? (
                <>
                  <Form.Label className="mt-0">Database URI</Form.Label>

                  <div style={{ position: "relative" }}>
                    <Form.Control
                      onChange={(e) => setData((prevData: any) => ({ ...prevData, databaseUrl: e.target.value }))}
                      placeholder="Add your own URI"
                      value={data.databaseUrl}
                    />

                    {data.databaseType !== "mongodb" && (
                      <svg
                        onClick={() => {
                          setData((prevData: any) => ({ ...prevData, databaseUrl: "" }));
                          setAddYourOwnUri(false);
                        }}
                        style={{
                          position: "absolute",
                          right: "25px",
                          top: "10px",
                          cursor: "pointer",
                          width: "20px",
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          d="M405 136.798L375.202 107 256 226.202 136.798 107 107 136.798 226.202 256 107 375.202 136.798 405 256 285.798 375.202 405 405 375.202 285.798 256z"
                          fill="red"
                        />
                      </svg>
                    )}
                  </div>
                </>
              ) : (
                <Select
                  placeholder="Select Database"
                  styles={darkMode ? customStyles : undefined}
                  value={selectedDbValue}
                  isDisabled={!data.databaseType}
                  options={[
                    ...(data.databaseType !== "mongodb"
                      ? databases.map((obj: any) => ({
                          value: obj.id,
                          label: `${obj.name} - ${obj.region}`,
                        }))
                      : []),
                    { label: "Create New Database", value: "Create New Database" },
                    { label: "Enter your own URI", value: "Enter your own URI" },
                  ]}
                  onChange={(e: any) => handleDbSelector(e)}
                />
              )}

              <div className="d-flex justify-content-end mt-3">
                <Button
                  onClick={() => {
                    navigate(`/project/${projectId}/${envId}/`);
                  }}
                  className="btn border mx-2 px-2"
                  size="sm"
                  variant=""
                >
                  Cancel
                </Button>
                <Button
                  disabled={loading || !data.databaseType || !data.databaseUrl ? true : false}
                  onClick={() => handleActivate()}
                  size="sm"
                  variant="primary"
                  className="px-3"
                >
                  Enable
                  {loading && <Spinner animation="border" size="sm" className="mx-1" />}
                </Button>
              </div>
            </>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ActivateAuthModal;
