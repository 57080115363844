import React, { useEffect, useState } from "react";
import { Col, Row, Card, Button, Modal, Breadcrumb, Spinner, Tabs, Tab } from "react-bootstrap";

import { useNavigate } from "react-router-dom";
import {
  cancelStripeSubscription,
  getStripeCustomerPortalLink,
  getStripeSubscriptionLink,
  getSubscriptionPlans,
  getUserInformation,
} from "../network/ApiAxios";
import PlaceholderLoading from "react-placeholder-loading";
import BillingBenefits from "../components/BillingBenefits/BillingBenefits";

export default function Billing() {
  const pageKey = "billing";
  const navigation = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [userInfo, setUserInfo] = useState<any>({}); // user info
  const [isLoadingPaymentMethod, setIsLoadingPaymentMethod] = useState(false);
  const [isLoadingBillingHistory, setIsLoadingBillingHistory] = useState(false);
  const [price, setPrice] = useState("");
  const [modaSubscriptionPlans, setModaSubscriptionPlans] = useState(false);
  const [isLoadingCheckout, setIsLoadingCheckout] = useState<any>(null);

  const [subscriptionPlans, setSubscriptionPlans] = useState<any>([]);
  const [loadingPlans, setLoadingPlans] = useState(true);

  const [isCustomSubscription, setIsCustomSubscription] = useState(false);

  React.useEffect(() => {
    document.title = "Genezio | Billing";
  }, []);

  // get modal state from query params
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const modal = urlParams.get("plans_modal");
    if (modal === "true") {
      setModaSubscriptionPlans(true);
    }
  }, []);

  useEffect(() => {
    async function asyncFetch() {
      setIsLoading(true);
      const resp: any = await getUserInformation();
      setIsLoading(false);

      if (resp.response && resp.response.data.error) {
        alert(resp.response.data.error.message);
        return;
      }

      if (resp.data && resp.data.error) {
        alert(resp.data.error.message);
        return;
      }

      if (resp.data && resp.data.user) {
        setUserInfo(resp.data.user);
        setPrice(resp.data.user.subscriptionPrice);
        setIsCustomSubscription(resp.data.user.customSubscription);
        return;
      }
    }
    asyncFetch();
  }, []);

  useEffect(() => {
    async function asyncFetch() {
      setLoadingPlans(true);
      const plans: any = await getSubscriptionPlans();
      setLoadingPlans(false);

      if (plans.response && plans.response.data.error) {
        alert(plans.response.data.error.message);
        return;
      }

      if (plans.data && plans.data.error) {
        alert(plans.data.error.message);
        return;
      }

      if (plans.data && plans.data.subscriptionPlans) {
        setSubscriptionPlans(
          plans.data.subscriptionPlans.map((plan: any) => {
            return {
              ...plan,
              benefits: JSON.parse(plan.benefits).benefits,
            };
          }),
        );
        return;
      }
    }
    asyncFetch();
  }, []);

  async function getStripeCheckout(plan: string) {
    setIsLoadingCheckout(plan);
    const resp: any = await getStripeSubscriptionLink(plan);
    setIsLoadingCheckout(null);

    if (resp.response && resp.response.data.error) {
      alert(resp.response.data.error.message);
      return;
    }

    if (resp.data && resp.data.error) {
      alert(resp.data.error.message);
      return;
    }

    if (resp.data && resp.data.checkoutUrl) {
      window.location.href = resp.data.checkoutUrl;
      return;
    }
  }

  async function getStripeCustomerPortal(ref: string) {
    if (ref === "payment_method") setIsLoadingPaymentMethod(true);
    if (ref === "billing_history") setIsLoadingBillingHistory(true);
    const resp: any = await getStripeCustomerPortalLink();

    if (resp.response && resp.response.data.error) {
      alert(resp.response.data.error.message);
      return;
    }

    if (resp.data && resp.data.error) {
      alert(resp.data.error.message);
      return;
    }

    if (resp.data && resp.data.customerPortalUrl) {
      window.location.href = resp.data.customerPortalUrl;
      return;
    }
  }

  async function cancelSubscription(subName: string) {
    setIsLoadingCheckout(subName);
    const resp: any = await cancelStripeSubscription();
    setIsLoadingCheckout(null);

    if (resp.response && resp.response.data.error) {
      alert(resp.response.data.error.message);
      return;
    }

    if (resp.data && resp.data.error) {
      alert(resp.data.error.message);
      return;
    }

    if (resp.data && resp.data.status === "ok") {
      navigation(0);
    }
  }

  return (
    <React.Fragment>
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item
              className="breadcrumb-item"
              onClick={() => navigation("/")}
              aria-current="page"
              style={{ paddingTop: "1px" }} // hack don;t change it
            >
              Home
            </Breadcrumb.Item>
            <Breadcrumb.Item className="breadcrumb-item" aria-current="page" active>
              Billing
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      {/* <!-- /breadcrumb --> */}

      {/* <!-- Modal --> */}
      <Modal
        className="billing-modal"
        show={modaSubscriptionPlans}
        style={{ border: "none" }}
        onHide={() => setModaSubscriptionPlans(false)}
        centered
      >
        <Modal.Header style={{ backgroundColor: "#6F42C1" }} className="px-4">
          <h4 className="modal-title subscription-modal-title">Genezio subscription plans</h4>
          <Button
            variant=""
            className="btn modal-subscription-btn-close"
            onClick={() => setModaSubscriptionPlans(false)}
          >
            x
          </Button>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "#F2F2F2", borderRadius: "0 0 5px 5px" }} className="text-center py-3">
          <Row className="justify-content-evenly">
            {/* Plans */}
            {subscriptionPlans?.map((plan: any) => {
              return (
                <Col key={plan?.id} sm={4} className="billing-col-subscription-type py-3 px-3 mb-2">
                  <div>
                    <h4 className="billing-col-subscription-title-name">
                      {!loadingPlans ? plan.name : <PlaceholderLoading shape="rect" width={150} height={25} />}
                    </h4>
                    <h4 className="billing-col-subscription-title-price">
                      {!loadingPlans ? (
                        <>{plan.tier === "0" ? "Free" : <>${plan.price}</>}</>
                      ) : (
                        <PlaceholderLoading shape="rect" width={100} height={25} />
                      )}
                      {plan.tier !== "0" && <span style={{ color: "black" }}>/month</span>}
                    </h4>

                    <BillingBenefits loadingPlans={loadingPlans} subscriptionPlan={plan} />
                  </div>
                  {!loadingPlans ? (
                    <>
                      {userInfo.subscriptionPlan === plan.name ? (
                        <Button
                          variant="primary"
                          className="btn btn-primary mt-3 billing-col-subscription-checklist-btn-current-plan"
                          disabled
                        >
                          Current Plan
                        </Button>
                      ) : (
                        <Button
                          variant="primary"
                          className="btn btn-primary mt-3 billing-col-subscription-checklist-btn-select-plan"
                          onClick={(e) =>
                            plan.name === "Hobby" ? cancelSubscription(plan.name) : getStripeCheckout(plan.name)
                          }
                        >
                          Select Plan{" "}
                          {isLoadingCheckout === plan.name ? <Spinner animation="border" size="sm" /> : null}
                        </Button>
                      )}
                    </>
                  ) : null}
                </Col>
              );
            })}
          </Row>
          <Row className="d-flex align-content-center justify-content-center mt-3 ">
            <a
              href="https://genezio.com/pricing/"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "underline" }}
            >
              <p className="how-billing-works-p">Compare our plans in detail</p>
            </a>
          </Row>
        </Modal.Body>
      </Modal>
      {/* <!-- /Modal --> */}

      {/* <!-- row  --> */}
      <Row>
        <Col sm={12} className="col-12">
          <Card>
            <Tabs
              id="controlled-tab-example"
              activeKey={pageKey}
              onSelect={(k) => {
                if (k === "profile") navigation("/profile");
                if (k === "billing") navigation("/billing");
              }}
            >
              <Tab eventKey="profile" title="Profile"></Tab>
              <Tab eventKey="billing" title="Billing" active disabled></Tab>
            </Tabs>
            {!isCustomSubscription ? (
              <>
                <Card.Header className="ps-3 ms-2">
                  <h5 className="card-title" style={{ textTransform: "none" }}>
                    Billing Information
                  </h5>
                </Card.Header>
                <Card.Body className="margin-custom-billing-body pt-0 example1-table card-body mx-4 p-4 card-billing-info">
                  <Row>
                    <Col sm={12} className="col-12">
                      <h4 className="dark-theme-white">Estimated Due</h4>
                      <p>
                        This is the estimate of the amount you owe for the next payment. If the transaction cannot be
                        processed, your access will be suspended.
                      </p>
                      <h3 className="dark-theme-white">
                        {isLoading ? (
                          <>
                            <Spinner variant="primary" animation="border" />
                          </>
                        ) : (
                          <>{price} EUR</>
                        )}
                      </h3>
                      <div className="mt-3">
                        <a
                          href="https://genezio.com/eula/"
                          target="_blank"
                          rel="noreferrer"
                          className="how-billing-works-p"
                        >
                          Terms & Conditions
                        </a>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>

                <Card.Header className="ps-3 ms-2 pb-0">
                  <h5 className="card-title" style={{ textTransform: "none" }}>
                    Change Subscription Plan
                  </h5>
                </Card.Header>
                <Card.Body className="margin-custom-billing-body pt-0 example1-table">
                  <p>
                    Subscription plan:{" "}
                    {isLoading ? (
                      <Spinner variant="primary" animation="border" size="sm" />
                    ) : (
                      <b> {userInfo.subscriptionPlan}</b>
                    )}
                  </p>
                  <Button variant="outline-primary" onClick={(e) => setModaSubscriptionPlans(true)}>
                    Change
                  </Button>
                </Card.Body>
              </>
            ) : (
              <>
                <Card.Header className="ps-3 ms-2 pb-0">
                  <h5 className="card-title" style={{ textTransform: "none" }}>
                    Subscription Plan Info
                  </h5>
                </Card.Header>
                <Card.Body className="margin-custom-billing-body pt-0 example1-table card-billing-info p-4 m-4">
                  <Row>
                    <Col sm={12} className="col-12">
                      <h4 className="dark-theme-white">Custom Subscription</h4>
                      <p>For more information about your subscription plan, please contact your account manager.</p>
                      <p>
                        Subscription plan:{" "}
                        {isLoading ? (
                          <Spinner variant="primary" animation="border" size="sm" />
                        ) : (
                          <b> {userInfo.subscriptionPlan}</b>
                        )}
                      </p>
                      <div className="mt-3">
                        <a
                          href="https://genezio.com/eula/"
                          target="_blank"
                          rel="noreferrer"
                          className="how-billing-works-p"
                        >
                          Terms & Conditions
                        </a>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </>
            )}
            {userInfo.subscriptionPlan !== "Hobby" && !isLoading && !isCustomSubscription ? (
              <>
                <Card.Header className="ps-3 ms-2 pb-0">
                  <h5 className="card-title" style={{ textTransform: "none" }}>
                    Update Payment Method
                  </h5>
                </Card.Header>
                <Card.Body className="margin-custom-billing-body pt-0 example1-table">
                  <p>
                    To make sure you don't lose access to your projects, please be aware of the payment method. If your
                    credit card expires, change it with a new one.
                  </p>
                  <Button
                    variant="primary"
                    className="custom-border-btn"
                    onClick={() => getStripeCustomerPortal("payment_method")}
                  >
                    Update Payment Method
                    {isLoadingPaymentMethod ? (
                      <Spinner variant="success" animation="border" size="sm" className="ms-1" />
                    ) : null}
                  </Button>
                </Card.Body>
                <Card.Header className="ps-3 ms-2 pb-0">
                  <h5 className="card-title" style={{ textTransform: "none" }}>
                    Billing History
                  </h5>
                </Card.Header>
                <Card.Body className="margin-custom-billing-body pt-0 example1-table">
                  <Button
                    variant="primary"
                    className="custom-border-btn"
                    onClick={() => getStripeCustomerPortal("billing_history")}
                  >
                    Get Billing History
                    {isLoadingBillingHistory ? (
                      <>
                        <Spinner variant="success" animation="border" size="sm" className="ms-1" />
                      </>
                    ) : null}
                  </Button>
                </Card.Body>
              </>
            ) : null}
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}
