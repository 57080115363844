export const handlerText = `import express from "express";
import serverless from "serverless-http";

const app = express();
export const handler = serverless(app);
`;

export const parseEnv = (src: string) => {
  const LINE =
    /(?:^|^)\s*(?:export\s+)?([\w.-]+)(?:\s*=\s*?|:\s+?)(\s*'(?:\\'|[^'])*'|\s*"(?:\\"|[^"])*"|\s*`(?:\\`|[^`])*`|[^#\r\n]+)?\s*(?:#.*)?(?:$|$)/gm;
  const arr: any = [];
  // Convert buffer to string
  let lines = src.toString();
  // Convert line breaks to same format
  lines = lines.replace(/\r\n?/gm, "\n");
  let match;
  while ((match = LINE.exec(lines)) != null) {
    const key = match[1];
    // Default undefined or null to empty string
    let value = match[2] || "";
    // Remove whitespace
    value = value.trim();
    // Check if double quoted
    const maybeQuote = value[0];
    // Remove surrounding quotes
    value = value.replace(/^(['"`])([\s\S]*)\1$/gm, "$2");
    // Expand newlines if double quoted
    if (maybeQuote === '"') {
      value = value.replace(/\\n/g, "\n");
      value = value.replace(/\\r/g, "\r");
    }
    // Push to array
    arr.push({ name: key, value: value });
  }

  return arr;
};

export type FrameworkSettings = {
  buildCommand: string;
  buildOutput: string;
  installCommand: string;
};

export function mapFrameworkToSettings(framework: string): FrameworkSettings {
  const frameworkSettings = frameworks.find((f) => f.slug === framework);
  if (!frameworkSettings) {
    return {
      buildCommand: "",
      buildOutput: "",
      installCommand: "npm install",
    };
  }
  return {
    buildCommand: frameworkSettings.buildCommand,
    buildOutput: frameworkSettings.outputDirectory,
    installCommand: frameworkSettings.installCommand,
  };
}

export const frameworks = [
  {
    name: "Next.js",
    slug: "next",
    outputDirectory: "",
    buildCommand: "next build",
    installCommand: "npm install",
  },
  {
    name: "Nuxt.js",
    slug: "nuxtjs",
    outputDirectory: "dist",
    buildCommand: "nuxt build",
    installCommand: "npm install",
  },
  {
    name: "Svelte",
    slug: "svelte",
    outputDirectory: "public",
    buildCommand: "rollup -c",
    installCommand: "npm install",
  },
  {
    name: "Create React App",
    slug: "react",
    outputDirectory: "build",
    buildCommand: "npm run build",
    installCommand: "npm install",
  },
  {
    name: "Gatsby.js",
    slug: "gatsby",
    outputDirectory: "public",
    buildCommand: "gatsby build",
    installCommand: "npm install",
  },
  {
    name: "Remix",
    slug: "remix",
    outputDirectory: "public",
    buildCommand: "remix build",
    installCommand: "npm install",
  },
  {
    name: "SolidStart (v0)",
    slug: "solidstart",
    outputDirectory: ".output",
    buildCommand: "solid-start build",
    installCommand: "npm install",
  },
  {
    name: "SvelteKit (v0)",
    slug: "sveltekit",
    outputDirectory: "public",
    buildCommand: "svelte-kit build",
    installCommand: "npm install",
  },
  {
    name: "Blitz.js (Legacy)",
    slug: "blitzjs",
    outputDirectory: "",
    buildCommand: "blitz build",
    installCommand: "npm install",
  },
  {
    name: "Astro",
    slug: "astro",
    outputDirectory: "dist",
    buildCommand: "astro build",
    installCommand: "npm install",
  },
  {
    name: "Hexo",
    slug: "hexo",
    outputDirectory: "public",
    buildCommand: "hexo generate",
    installCommand: "npm install",
  },
  {
    name: "Eleventy",
    slug: "eleventy",
    outputDirectory: "_site",
    buildCommand: "npx @11ty/eleventy",
    installCommand: "npm install",
  },
  {
    name: "Docusaurus (v2)",
    slug: "docusaurus-2",
    outputDirectory: "build",
    buildCommand: "docusaurus build",
    installCommand: "npm install",
  },
  {
    name: "Docusaurus (v1)",
    slug: "docusaurus",
    outputDirectory: "build",
    buildCommand: "docusaurus-build",
    installCommand: "npm install",
  },
  {
    name: "Preact",
    slug: "preact",
    outputDirectory: "build",
    buildCommand: "preact build",
    installCommand: "npm install",
  },
  {
    name: "SolidStart (v1)",
    slug: "solidstart-1",
    outputDirectory: ".output",
    buildCommand: "vinxi build",
    installCommand: "npm install",
  },
  {
    name: "Dojo",
    slug: "dojo",
    outputDirectory: "output/dist",
    buildCommand: "dojo build",
    installCommand: "npm install",
  },
  {
    name: "Ember.js",
    slug: "ember",
    outputDirectory: "dist",
    buildCommand: "ember build",
    installCommand: "npm install",
  },
  {
    name: "Vue.js",
    slug: "vue",
    outputDirectory: "dist",
    buildCommand: "vue-cli-service build",
    installCommand: "npm install",
  },
  {
    name: "Scully",
    slug: "scully",
    outputDirectory: "dist",
    buildCommand: "ng build \u0026\u0026 scully",
    installCommand: "npm install",
  },
  {
    name: "Ionic Angular",
    slug: "ionic-angular",
    outputDirectory: "www",
    buildCommand: "ng build",
    installCommand: "npm install",
  },
  {
    name: "Angular",
    slug: "angular",
    outputDirectory: "dist",
    buildCommand: "ng build",
    installCommand: "npm install",
  },
  {
    name: "Polymer",
    slug: "polymer",
    outputDirectory: "build",
    buildCommand: "polymer build",
    installCommand: "npm install",
  },
  {
    name: "SvelteKit (v1)",
    slug: "sveltekit-1",
    outputDirectory: "public",
    buildCommand: "vite build",
    installCommand: "npm install",
  },
  {
    name: "Ionic React",
    slug: "ionic-react",
    outputDirectory: "build",
    buildCommand: "react-scripts build",
    installCommand: "npm install",
  },
  {
    name: "Gridsome",
    slug: "gridsome",
    outputDirectory: "dist",
    buildCommand: "gridsome build",
    installCommand: "npm install",
  },
  {
    name: "UmiJS",
    slug: "umijs",
    outputDirectory: "dist",
    buildCommand: "umi build",
    installCommand: "npm install",
  },
  {
    name: "Sapper",
    slug: "sapper",
    outputDirectory: "__sapper__/export",
    buildCommand: "sapper export",
    installCommand: "npm install",
  },
  {
    name: "Saber",
    slug: "saber",
    outputDirectory: "public",
    buildCommand: "saber build",
    installCommand: "npm install",
  },
  {
    name: "Stencil",
    slug: "stencil",
    outputDirectory: "www",
    buildCommand: "stencil build",
    installCommand: "npm install",
  },
  {
    name: "RedwoodJS",
    slug: "redwoodjs",
    outputDirectory: "",
    buildCommand: "yarn rw deploy vercel",
    installCommand: "npm install",
  },
  {
    name: "Hugo",
    slug: "hugo",
    outputDirectory: "",
    buildCommand: "hugo --gc",
    installCommand: "None",
  },
  {
    name: "Jekyll",
    slug: "jekyll",
    outputDirectory: "",
    buildCommand: "jekyll build",
    installCommand: "",
  },
  {
    name: "Brunch",
    slug: "brunch",
    outputDirectory: "public",
    buildCommand: "brunch build --production",
    installCommand: "npm install",
  },
  {
    name: "Middleman",
    slug: "middleman",
    outputDirectory: "build",
    buildCommand: "bundle exec middleman build",
    installCommand: "",
  },
  {
    name: "Zola",
    slug: "zola",
    outputDirectory: "public",
    buildCommand: "zola build",
    installCommand: "None",
  },
  {
    name: "Hydrogen (v1)",
    slug: "hydrogen",
    outputDirectory: "dist",
    buildCommand: "shopify hydrogen build",
    installCommand: "npm install",
  },
  {
    name: "Vite",
    slug: "vite",
    outputDirectory: "dist",
    buildCommand: "vite build",
    installCommand: "npm install",
  },
  {
    name: "VitePress",
    slug: "vitepress",
    outputDirectory: "docs/.vitepress/dist",
    buildCommand: "vitepress build docs",
    installCommand: "npm install",
  },
  {
    name: "VuePress",
    slug: "vuepress",
    outputDirectory: "src/.vuepress/dist",
    buildCommand: "vuepress build src",
    installCommand: "npm install",
  },
  {
    name: "Parcel",
    slug: "parcel",
    outputDirectory: "dist",
    buildCommand: "parcel build",
    installCommand: "npm install",
  },
  {
    name: "FastHTML (Experimental)",
    slug: "fasthtml",
    outputDirectory: "N/A",
    buildCommand: "",
    installCommand: "`pip install`",
  },
  {
    name: "Sanity",
    slug: "sanity",
    outputDirectory: "dist",
    buildCommand: "sanity build",
    installCommand: "npm install",
  },
  {
    name: "Storybook",
    slug: "storybook",
    outputDirectory: "storybook-static",
    buildCommand: "storybook build",
    installCommand: "npm install",
  },
  {
    name: "Other",
    slug: "other",
    outputDirectory: "",
    buildCommand: "",
    installCommand: "npm install",
  },
];
